<template>
  <v-dialog
    v-model="shouldShowDialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '32%'"
    persistent
    @keydown.esc="applyFilters"
    @keydown.enter="applyFilters"
  >
    <v-card
      style="max-height: 70vh; display: flex; flex-direction: column;"
    >
      <v-card-title
        style="background-color: #eeeeee; font-size: 1rem;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="applyFilters"
        >
          <v-icon
            color="grey darken-2"
            size="1.5rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pt-5"
        style="overflow-y: auto; flex-grow: 1;"
      >
        <template
          v-if="column && column.table_filters && column.table_filters.length"
        >
          <template
            v-for="(field, filterColumnIndex) in column.table_filters"
          >
            <v-row
              :key="filterColumnIndex"
              class="mb-5"
            >
              <v-col>
                <div
                  v-if="field.type === 'text' || field.type === 'date'"
                  style="width: 100%; position: relative;"
                >
                  <custom-text-field
                    ref="filterField"
                    v-model="filters[field.key + '|' + field.label]"
                    :use-label="true"
                    :field="field"
                  />
                </div>

                <div
                  v-if="field.type === 'autocomplete'"
                  style="width: 100%; position: relative;"
                >
                  <custom-autocomplete
                    v-model="filters[field.key + '|' + field.label]"
                    ref="filterField"
                    :options="field.autocomplete_options"
                    :dense="true"
                    :main-object="mainObject"
                    :field="field"
                    :hide-details="true"
                    :use-label="true"
                  />
                </div>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card-text>
      <v-card-actions
        style="background-color: #eeeeee;"
      >
        <v-spacer />
        <v-btn
          class="primary mr-2"
          text
          @click="applyFilters"
        >
          <span
            style="font-size: 0.6125rem;"
          >
            {{ $t('base.close') }}
          </span>
        </v-btn>
        <v-btn
          class="green darken-3"
          style="color: white;"
          text
          @click="applyFilters"
        >
           <span
             style="font-size: 0.6125rem;"
           >
              {{ $t('base.apply') }}
           </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextField from '../custom-fields/CustomTextField.vue'
import { cloneDeep, forEach } from 'lodash'
import CustomAutocomplete from '../custom-fields/CustomAutocomplete.vue'

export default {
  name: 'DataTableFilterDialog',

  components: {
    CustomAutocomplete,
    CustomTextField
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    column: {
      type: Object,
      default: () => {}
    },
    removeFiltersFormFields: {
      type: Array,
      default: () => []
    },
    mainObject: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'apply-filters',
    'filters-form-fields-removed'
  ],

  data () {
    return {
      filters: {},
      customized_filters: {}
    }
  },

  computed: {
    shouldShowDialog: {
      get () {
        return this.showDialog
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    shouldShowDialog: {
      immediate: true,
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            const fields = this.$refs.filterField
            if (fields && fields.length > 0) {
              fields[0].focus()
            }
          })
        }
      }
    },
    removeFiltersFormFields: {
      immediate: true,
      handler (filtersToRemove) {
        const that = this
        if (filtersToRemove && filtersToRemove.length) {
          forEach(filtersToRemove, filterKey => {
            that.$delete(this.filters, filterKey)
            that.$emit('filters-form-fields-removed', filterKey)
          })
        }
      }
    }
  },

  methods: {
    applyFilters () {
      const filtersCopy = cloneDeep(this.filters)
      this.customized_filters = {}
      Object.keys(this.column.display_data_config).map(() => {
        Object.keys(filtersCopy).map(key => {
          const keyPart = key.split('|')[0]
          if (this.column.display_data_config[keyPart] && !this.customized_filters[key] && this.column.display_data_config[keyPart].custom_value) {
            filtersCopy[key] = this.column.display_data_config[keyPart].custom_value(filtersCopy[key])
            this.customized_filters[key] = true
          }
        })
      })

      this.$emit('apply-filters', filtersCopy)
    }
  }
}
</script>
