<!--
This template represents predefined collection of objects in table form.
Collection of objects should have a fixed length of objects, with predefined objects.
By passing collection of objects, each property in object will present a field (text field or whatever type of field you need).
-->
<template>
  <div
    v-if="column && column.row_labels && column.row_labels.length"
  >
    <!--Headers (object property names)-->
    <template
      v-if="column.headers"
    >
      <v-row
        class="border-bottom"
      >
        <v-col
          v-for="header in column.headers"
          :key="header + '_column'"
          :cols="Math.floor(12 / column.headers.length)"
        >
          <span
            style="font-weight: bold;  font-size: 0.8125rem; color: var(--v-primary-base);"
          >
            {{ header }}
          </span>
        </v-col>
      </v-row>
    </template>

    <!--Object properties in table form-->
    <template
      v-if="column.object_properties && column.object_properties.length"
    >
      <v-row
        v-for="(rowLabel,rowLabelIndex) in column.row_labels"
        :key="rowLabel + '_row'"
      >
        <!--Each objects label-->
        <v-col
          :cols="Math.floor(12 / column.headers.length)"
        >
          <span
            style="color: rgba(0, 0, 0, 0.54); font-weight: bold; font-size: 0.8125rem;"
          >
            {{ rowLabel }}
          </span>
        </v-col>

        <!--Object properties fields-->
        <v-col
          v-for="(field, fieldIndex) in column.object_properties"
          :key="fieldIndex"
          :cols="Math.floor(12 / column.headers.length)"
        >
          <!--Time picker field-->
          <div
            class="field"
            v-if="field.type === 'time'"
            style="position:relative;"
            @dblclick="edit(field)"
          >
            <custom-time-picker
              v-model="item[column.collection_name][rowLabelIndex][field.key]"
              :field="field"
              :hide-details="true"
              :use-label="true"
              :validation-errors="validationErrors"
              :collection-object-index="rowLabelIndex.toString()"
              :collection-name="column.collection_name ? column.collection_name : null"
              should-set-tab-validation-errors
              :append-icon="!isEditClicked ? '' : 'mdi-clock-outline'"
              :disabled="!field.editable || !isEditClicked"
              :edit-clicked="isEditClicked"
              @edit-clicked="edit(field)"
              @set-tab-validation-error-icon="$emit('set-tab-validation-error-icon', tabIndex)"
              @remove-tab-validation-error-icon="$emit('remove-tab-validation-error-icon', tabIndex)"
              @clear-validation-errors="key => $emit('clear-validation-errors', key)"
            />
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { forEach, isObject } from 'lodash'
import CustomTimePicker from '../../custom-fields/CustomTimePicker.vue'

export default {
  name: 'CollectionObjectsLayout',

  components: {
    CustomTimePicker
  },

  props: {
    column: {
      type: Object,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    itemObject: {
      type: Object,
      default: () => {}
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    initialAutocompletes: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isEditClicked: {
      get () {
        return this.editClicked
      },
      set () {
        this.$emit('edit-clicked')
      }
    },
    item: {
      get () {
        return this.itemObject
      },
      set (val) {
        console.log(val)
        this.$emit('item-object-updated', val)
      }
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler (item) {
        if (this.column && this.column.collection_name && !item[this.column.collection_name]) {
          if (this.column.row_labels && this.column.object_properties && this.column.row_labels.length && this.column.object_properties.length) {
            item[this.column.collection_name] = []
            forEach(this.column.row_labels, () => {
              const newObject = {}
              forEach(this.column.object_properties, (property) => {
                if (property && property.key && !newObject[property.key]) {
                  newObject[property.key] = property.default_value
                }
              })
              item[this.column.collection_name].push(newObject)
            })
          }
        }
      }
    }
  },

  methods: {
    isObject,
    edit (field) {
      if (!field) {
        this.isEditClicked = true
      }
      else if (!this.isEditClicked && field.editable) {
        this.isEditClicked = true
      }
    }
  }
}
</script>

<style scoped>
.border-bottom {
  border-bottom: thin solid lightgray;
}
</style>
