<template>
  <v-dialog
    v-model="showDialogComputed"
    persistent
    :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '80%'"
  >
    <v-card>
      <v-card-title
        style="background-color: #eeeeee; font-size: 1.2rem;"
      >
        <v-icon
          class="mr-3"
          style="color: rgb(161, 56, 77);"
        >
          mdi-alert
        </v-icon>
        {{ title }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="cancel"
        >
          <v-icon
            color="grey darken-2"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-row
          >
          <span
            style="font-style: italic;"
            class="mt-5"
          >
            {{ message }}
          </span>
          </v-row>
      </v-card-text>
      <v-card-actions
        style="background-color: #eeeeee;"
      >
        <v-spacer />
        <v-btn
          class="green darken-3"
          style="color: white;"
          text
          @click="cancel"
        >
          {{ $t('base.no') }}
        </v-btn>
        <v-btn
          class="red darken-3"
          style="color: white;"
          text
          @click="confirm"
        >
          {{ $t('base.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ChangesNotSavedDialog',

  props: {
    message: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'cancel',
    'confirm'
  ],

  computed: {
    showDialogComputed: {
      get () {
        return this.showDialog
      },
      set (val) {
        return val
      }
    }
  },

  methods: {
    cancel () {
      this.showDialogComputed = false
      this.$emit('cancel')
    },

    confirm () {
      this.showDialogComputed = false
      this.$emit('confirm')
    }
  }
}
</script>
