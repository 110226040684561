<template>
  <v-dialog
    v-model="showDialog"
    :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '80%'"
    persistent
    @keydown.enter="sendData"
  >
    <v-card>
      <v-card-title
        style="background-color: #eeeeee; font-size: 1rem;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-2"
            size="1.5rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-row
            v-if="dialogMessage"
          >
            <v-col>
              <span
                style="font-style: italic;"
                class="mt-5"
              >
                {{ dialogMessage }}
              </span>
            </v-col>
          </v-row>
        <v-row
          v-for="(field, fieldIndex) in dialogFields"
          :key="fieldIndex"
        >
          <v-col>
            <div
              v-if="field.type === 'dependable' && rowItem[field.depends_on]"
            >
              <custom-text-field
                v-if="field.value ? field.states[rowItem[field.depends_on][field.value]].type === 'text' : field.states[rowItem[field.depends_on]].type === 'text'"
                v-model="formData[field.key]"
                :field="field.states[rowItem[field.depends_on][field.value]]"
                :suffix="field.states[rowItem[field.depends_on][field.value]].suffix"
              />
              <custom-date-picker
                v-if="field.value ? field.states[rowItem[field.depends_on][field.value]].type === 'date' : field.states[rowItem[field.depends_on]].type === 'date'"
                v-model="formData[field.key]"
                :label="field && field.label ? field.label : ''"
              />
            </div>
            <div
              v-else-if="field.type === 'text'"
            >
              <custom-text-field
                v-model="formData[field.key]"
                :rules="field.rules ? field.rules : []"
                :validation-errors="validationErrors"
                :field="field"
                @clear-validation-errors="clearValidationErrors(field.key)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        style="background-color: #eeeeee;"
      >
        <v-spacer />
        <v-btn
          class="primary mr-3"
          text
          @click="closeDialog"
        >
          <span
            style="font-size: 0.6125rem;"
          >
            {{ $t('base.close') }}
          </span>
        </v-btn>
        <v-btn
          class="green darken-3"
          style="color: white;"
          text
          @click="sendData"
        >
           <span
             style="font-size: 0.6125rem;"
           >
            {{ $t('base.save') }}
           </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { api } from '@/global/services/api'
import { isEmpty } from 'lodash'
import CustomDatePicker from '../custom-fields/CustomDatePicker.vue'
import CustomTextField from '../custom-fields/CustomTextField.vue'

export default {
  name: 'FormDataDialog',

  components: {
    CustomTextField,
    CustomDatePicker
  },

  props: {
    dialogMessage: {
      type: String,
      default: ''
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    apiConfig: {
      type: Object,
      default: null
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    dialogFields: {
      type: Array,
      default: () => []
    },
    rowItem: {
      type: Object,
      default: () => {}
    },
    itemId: {
      type: Number,
      default: null
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.openDialog
      },
      set (val) {
        return val
      }
    }
  },

  data () {
    return {
      formData: {},
      validationErrors: {}
    }
  },

  methods: {
    closeDialog () {
      this.showDialog = false
      this.formData = {}
      this.validationErrors = {}
      this.$emit('close-form-data-dialog')
    },

    async sendData () {
      try {
        if (!isEmpty(this.apiConfig) && this.itemId && this.apiConfig.method) {
          const formData = this.getFormData()
          if (this.apiConfig._method) {
            formData.append('_method', this.apiConfig._method)
          }

          await api()[this.apiConfig.module][this.apiConfig.method](this.apiConfig.route + this.itemId, formData)

          this.showDialog = false
          this.formData = {}
          this.$emit('close-form-data-dialog', true)
        }
      }
      catch (exception) {
        console.log('Error occurred in FormDataDialog. Exception: ' + exception)
        if (exception && exception.response && exception.response.data && exception.response.data.errors) {
          this.validationErrors = exception.response.data.errors
        }
      }
    },

    getFormData () {
      const formData = new FormData()

      for (const key in this.formData) {
        if (typeof this.formData[key] === 'object' && this.formData[key] !== null && this.formData[key].id) {
          formData.append(key, this.formData[key].id)
        }
        else formData.append(key, this.formData[key] === null ? '' : this.formData[key])
      }

      return formData
    },

    clearValidationErrors (key) {
      this.$delete(this.validationErrors, key)
    }
  }
}
</script>
