<template>
  <v-dialog
    v-model="showPdfsPreviewDialog"
    :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '80%'"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title
        style="background-color: #eeeeee; font-size: 0.8725rem;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-2"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <template
          v-if="existingPdfs && Object.keys(existingPdfs).length"
        >
          <v-row
            v-for="pdfKey in Object.keys(existingPdfs)"
            :key="pdfKey"
            :class="Object.keys(existingPdfs).length > 1 ? 'border-bottom' : ''"
          >
            <v-col
              class="d-flex justify-start mt-5"
            >
              <v-icon
                color="red"
              >
                mdi-file-pdf-box
              </v-icon>
              <span
                class="d-flex align-center font-weight-bold"
              >
               {{ existingPdfs[pdfKey] && existingPdfs[pdfKey].name ? existingPdfs[pdfKey].name : '' }}
            </span>
            </v-col>

            <v-col
              class="d-flex justify-end mt-5"
            >
              <v-tooltip
                top
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="success mr-5"
                    style="font-size: 0.6125rem;"
                    @click="downloadPdf(pdfKey)"
                  >
                    <v-icon
                    >
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('base/patrol-vehicle.tips_form_recorded_audio_download') }}
                </span>
              </v-tooltip>

              <v-tooltip
                top
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="error"
                    style="font-size: 0.6125rem;"
                    @click="removedPdf(pdfKey)"
                  >
                    <v-icon
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('base.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>

        <template
          v-else
        >
          <v-row
          >
          <span
            style="font-style: italic;"
            class="mt-5"
          >
            {{ $t('fleet/vehicle.no_uploaded_pdfs') }}
          </span>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions
        style="background-color: #eeeeee;"
      >
        <v-spacer />
        <v-btn
          color="primary"
          style="font-size: 0.6125rem;"
          @click="closeDialog"
        >
          {{ $t('base.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { api } from '@/global/services/api'

export default {
  name: 'PdfsPreviewDialog',

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    pdfs: {
      type: [Object, Array],
      default: null
    },
    field: {
      type: Object,
      default: null
    },
    showActions: {
      type: Boolean,
      default: true
    },
    dialogTitle: {
      type: String,
      default: ''
    }
  },

  emits: [
    'close-pdfs-preview-dialog',
    'updated-pdfs',
    'refresh-table'
  ],

  computed: {
    showPdfsPreviewDialog: {
      get () {
        return this.showDialog
      },
      set (val) {
        return val
      }
    },

    existingPdfs: {
      get () {
        return this.pdfs
      },
      set (val) {
        return val
      }
    }
  },

  methods: {
    closeDialog () {
      this.showPdfsPreviewDialog = false
      this.$emit('close-pdfs-preview-dialog')
    },

    downloadPdf (pdfKey) {
      const link = document.createElement('a')
      link.href = this.field && this.field.read_src_from ? this.existingPdfs[pdfKey][this.field.read_src_from] : this.existingPdfs[pdfKey].original_url
      link.download = this.existingPdfs[pdfKey].name
      link.click()
    },

    async removedPdf (pdfKey) {
      try {
        if (this.field && this.field.actions && this.field.actions.delete) {
          await api()[this.field.actions.delete.module][this.field.actions.delete.method](this.field.actions.delete.route + this.existingPdfs[pdfKey][this.field.actions.delete.parameter])

          if (this.existingPdfs[pdfKey]) {
            this.$delete(this.existingPdfs, pdfKey)
            this.$emit('updated-pdfs', this.existingPdfs)
            this.$emit('refresh-table')
          }
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to remove pdf file in PdfPreviewDialog. Exception: ', exception)
      }
    }
  }
}
</script>

<style scoped>
.border-bottom {
  border-bottom: thin solid lightgray;
}
</style>
