<template>
  <v-container
    v-if="!isEmpty(config) && !isEmpty(config.mainViewConfig) && !isEmpty(config.mainViewConfig.tabsConfig)"
    class="v-container"
    fluid
  >
    <v-overlay
      v-if="loader || savingData"
      style="left: 12px; bottom: 12px"
      opacity="-0.2"
      absolute
    >
      <v-progress-circular
        indeterminate
        :color="'var(--v-primary-base)'"
        size="60"
      />
    </v-overlay>

    <v-card
      v-if="!loader"
      :disabled="savingData"
      class="v-card"
    >
      <!--      BREADCRUMBS TOOLBAR-->
      <v-toolbar
        dense
        flat
      >
        <v-row>
          <v-col>
            <div
              v-if="!isEmpty(config.breadCrumbsConfig) && config.breadCrumbsConfig.parts && config.breadCrumbsConfig.parts.length"
            >
              <v-icon
                v-if="!isEmpty(config.breadCrumbsConfig.prependIcon) && config.breadCrumbsConfig.prependIcon.value"
                :style="config.breadCrumbsConfig.prependIcon.style || breadCrumbsPrependIconDefaultStyle"
              >
                {{ config.breadCrumbsConfig.prependIcon.value }}
              </v-icon>
              <span
                v-for="(part, partIndex) in config.breadCrumbsConfig.parts"
                :key="partIndex"
              >
            <span
              :style="part.style ? part.style : defaultBreadCrumbPartStyle"
              :class="partIndex !== config.breadCrumbsConfig.parts.length - 1 ? '' : 'last-breadcrumbs-part'"
            >
              {{ part.value }}
            </span>
            <span
              v-if="partIndex !== config.breadCrumbsConfig.parts.length - 1"
              style="color: var(--v-primary-base);"
            >
              {{ config.breadCrumbsConfig.delimeter }}
            </span>
          </span>
              <v-icon
                v-if="!isEmpty(config.breadCrumbsConfig.appendIcon) && config.breadCrumbsConfig.appendIcon.value"
                :style="config.breadCrumbsConfig.appendIcon.style ? config.breadCrumbsConfig.appendIcon.style : breadCrumbsAppendIconDefaultStyle"
              >
                {{ config.breadCrumbsConfig.appendIcon.value }}
              </v-icon>
            </div>
          </v-col>
          <!--Automatic refresh checkbox-->
          <v-col
            v-if="config.auto_refresh && !isEmpty(config.auto_refresh)"
            class="d-flex justify-end"
          >
            <v-checkbox
              v-model="autoRefreshChecked"
              :label="config.auto_refresh.label ? config.auto_refresh.label : ''"
              :disabled="!showAutoRefresh"
              class="autorefresh-checkbox"
              hide-details
              @change="updateAutoRefresh"
            />
          </v-col>
        </v-row>
      </v-toolbar>

      <!--      BUTTONS TOOLBAR-->
      <v-toolbar
        dense
        flat
        style="margin-bottom: 1rem;"
      >
        <v-row>
          <!--Buttons-->
          <v-col
            cols="6"
            class="d-flex justify-start"
          >
            <Button
              v-if="config.buttons.back"
              :type="'back'"
              @click="backFromDetails"
            />

            <Button
              v-if="config.buttons.edit && !editClicked && showEditButton && !showDetailsActionButtons"
              :type="'edit'"
              class="ml-3"
              @click="edit"
            />

            <Button
              v-if="(config.buttons.cancel && editClicked) || showDetailsActionButtons"
              :type="'cancel'"
              class="ml-3"
              @click="cancelEdit"
            />

            <Button
              v-if="(config.buttons.save && editClicked) || showDetailsActionButtons"
              class="ml-3"
              @click="saveData"
            />
          </v-col>
        </v-row>
      </v-toolbar>

      <!--      MAIN VIEW-->
      <v-card-text
        class="v-card-text"
      >
        <v-row
          no-gutters
        >
          <!--Tab list-->
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 2"
            :class="$vuetify.breakpoint.mobile ? 'border-bottom' : 'border-right'"
          >
            <v-tabs
              v-model="currentTab"
              :vertical="!$vuetify.breakpoint.mobile"
              :class="$vuetify.breakpoint.mobile ? 'tabs-mobile' :'tabs-desktop'"
              :show-arrows="$vuetify.breakpoint.mobile"
              class="ml-5"
              style="width: 90% !important;"
            >
              <template
                v-for="(tabItem, tabIndex) in config.mainViewConfig.tabsConfig"
              >
                <v-tab
                  v-if="tabItem.visible"
                  :key="tabIndex"
                  class="d-flex justify-space-between align-center"
                  :class="editClicked ? 'edit-mode' : ''"
                  :disabled="editClicked && !tabItem.editable"
                >
                  {{ tabItem.label }}
                  <v-icon
                    v-if="validationErrorTabs.includes(tabIndex)"
                    class="ml-1 red-muted--text"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </v-tab>
              </template>
            </v-tabs>
          </v-col>
          <!--Tab view-->
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 10"
          >
            <v-tabs-items
              v-model="currentTab"
            >
              <template
                v-for="(tabItem, tabIndex) in config.mainViewConfig.tabsConfig"
              >
                <v-tab-item
                  v-if="!isEmpty(tabItem.view) && tabItem.visible"
                  :key="tabIndex + '_content'"
                >
                  <v-card
                    flat
                    class="px-4"
                  >
                    <v-card-text
                      style="line-height: unset !important;"
                    >
                      <v-row
                        v-for="(row, rowIndex) in tabItem.view.rows"
                        :key="rowIndex"
                        style="line-height: unset !important;"
                      >
                        <v-col
                          v-for="(column, columnIndex) in row.columns"
                          :key="columnIndex"
                          class="align-start justify-start mr-5"
                          style="line-height: unset !important;"
                          :cols="$vuetify.breakpoint.mobile ? '' : (row.columns && row.columns.length === 1 && column.layout === 'label-value' ? 6 : '')"
                        >
                          <!--                          CAN BUS-->
                          <template
                            v-if="column.layout === 'can-bus'"
                          >
                            <can-bus-speedometer
                              :namespace="column.namespace"
                            />
                          </template>

                          <!--LABEL-VALUE LAYOUT-->
                          <template
                            v-else-if="column.layout === 'label-value'"
                          >
                            <label-value-layout
                              :row="row"
                              :column="column"
                              :item-object="itemObject"
                              :initial-autocompletes="initialAutocompletes"
                              :initial-tab="initialTab"
                              :edit-clicked="editClicked"
                              :tab-index="tabIndex"
                              :validation-errors="validationErrors"
                              @edit-clicked="edit"
                              @item-object-updated="updateItemObject"
                              @clear-validation-errors="clearValidationErrors"
                              @set-tab-validation-error-icon="setValidationTabErrorIcon"
                              @remove-tab-validation-error-icon="removeValidationTabErrorIcon"
                            />
                          </template>

                          <!--COLLECTION-OBJECTS LAYOUT-->
                          <template
                            v-else-if="column.layout === 'collection-objects'"
                          >
                            <collection-objects-layout
                              :row="row"
                              :column="column"
                              :item-object="itemObject"
                              :edit-clicked="editClicked"
                              :tab-index="tabIndex"
                              :validation-errors="validationErrors"
                              @edit-clicked="edit"
                              @item-object-updated="updateItemObject"
                              @clear-validation-errors="clearValidationErrors"
                              @set-tab-validation-error-icon="setValidationTabErrorIcon"
                              @remove-tab-validation-error-icon="removeValidationTabErrorIcon"
                            />
                          </template>

                          <!--DATA TABLE LAYOUT-->
                          <template
                            v-else
                          >
                            <data-table-layout
                              :column="column"
                              :tab-item="tabItem"
                              :tab-index="tabIndex"
                              :item-object="itemObject"
                              :current-tab-id="currentTab"
                              :fetch="fetch"
                              :save-details-clicked="saveDetailsClicked"
                              :cancel-details-clicked="cancelDetailsClicked"
                              @set-tab-validation-error-icon="setValidationTabErrorIcon"
                              @remove-tab-validation-error-icon="removeValidationTabErrorIcon"
                              @show-details-action-buttons="showDetailsActionButtonsHandler"
                              @hide-details-action-buttons="hideDetailsActionButtonsHandler"
                              @reset-buttons-click="resetButtonsClick"
                            />
                          </template>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Changes-not-saved-dialog
      :show-dialog="showChangesNotSavedDialog"
      :title="$t('fleet/vehicle.data_not_saved_title')"
      :message="$t('fleet/vehicle.data_not_saved_message')"
      @cancel="showChangesNotSavedDialog = false"
      @confirm="confirmNotChangedDataExit"
    />
  </v-container>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { convertFromPathToBlob, convertToBase64 } from '@/global/services/helpers/files'
import DataTableLayout from './components/tab-view-layouts/data-table-layout/DataTableLayout.vue'
import LabelValueLayout from './components/tab-view-layouts/label-value-layout/LabelValueLayout.vue'
import CanBusSpeedometer from '../../../../modules/fleet-module/components/CanBusSpeedometer.vue'
import { api } from '@/global/services/api'
import CollectionObjectsLayout from './components/tab-view-layouts/collection-objects-layout/CollectionObjectsLayout.vue'
import { createNamespacedHelpers } from 'vuex'
import Button from '@/global/components/buttons/Button.vue'
import ChangesNotSavedDialog from './components/dialogs/ChangesNotSavedDialog.vue'
const { mapGetters: mapGettersBase, mapActions: mapActionsBase } = createNamespacedHelpers('base/config')

export default {
  name: 'TabMainView',

  components: {
    ChangesNotSavedDialog,
    Button,
    CollectionObjectsLayout,
    CanBusSpeedometer,
    LabelValueLayout,
    DataTableLayout
  },

  props: {
    config: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    fetch: {
      type: Function,
      default: null
    },
    module: {
      type: String,
      required: true
    },
    route: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    itemId: {
      type: [Number, String],
      default: null
    },
    autoRefresh: {
      type: Boolean,
      default: false
    },
    initialAutocompletes: {
      type: [Array, Object],
      default: () => []
    },
    loader: {
      type: Boolean,
      default: false
    },
    initialTab: {
      type: String,
      default: ''
    },
    redirectPath: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      currentTab: 0,
      defaultBreadCrumbPartStyle: {
        fontSize: '0.8725rem',
        color: 'var(--v-primary-base)',
        paddingRight: '7px',
        paddingLeft: '7px'
      },
      breadCrumbsPrependIconDefaultStyle: {
        color: 'var(--v-primary-base)'
      },
      breadCrumbsAppendIconDefaultStyle: {
        color: 'var(--v-primary-base)'
      },
      editClicked: false,
      itemObject: {},
      originalItemObject: {},
      refreshInterval: null,
      validationErrors: {},
      validationErrorTabs: [],
      showEditButton: true,
      showAutoRefresh: true,
      autoRefreshChecked: false,
      showDetailsActionButtons: false,
      saveDetailsClicked: false,
      cancelDetailsClicked: false,
      showChangesNotSavedDialog: false,
      savingData: false
    }
  },

  computed: {
    ...mapGettersBase(['userConfig']),

    tabNameMap () {
      return Object.keys(this.config.mainViewConfig.tabsConfig).reduce((map, tabName, index) => {
        map[index] = this.config.mainViewConfig.tabsConfig[tabName]
        return map
      }, {})
    }
  },

  watch: {
    item: {
      deep: true,
      immediate: true,
      async handler (item) {
        this.itemObject = cloneDeep(item)
        this.originalItemObject = cloneDeep(this.itemObject)
      }
    },
    currentTab: {
      immediate: true,
      handler (val) {
        const currentTab = this.tabNameMap[val]
        if (!currentTab.editable) {
          this.showEditButton = false
          this.editClicked = false
        }
        else {
          this.showEditButton = true
        }
      }
    },
    autoRefreshChecked: {
      immediate: true,
      handler (value) {
        this.setAutoRefresh(value)
      }
    },
    editClicked: {
      immediate: true,
      handler (value) {
        if (value) {
          clearInterval(this.refreshInterval)
        }
        else {
          this.setAutoRefresh(this.autoRefreshChecked)
        }
        this.showAutoRefresh = !value
      }
    },
    showDetailsActionButtons: {
      immediate: true,
      handler (value) {
        if (value) {
          clearInterval(this.refreshInterval)
        }
        else {
          this.setAutoRefresh(this.autoRefreshChecked)
        }
        this.showAutoRefresh = !value
      }
    },
    userConfig: {
      immediate: true,
      handler (val) {
        if (val && this.config && this.config.auto_refresh && this.config.auto_refresh.user_config_ui_view_name && this.config.auto_refresh.parameter && val[this.config.auto_refresh.user_config_ui_view_name]) {
          this.autoRefreshChecked = val[this.config.auto_refresh.user_config_ui_view_name][this.config.auto_refresh.parameter] && val[this.config.auto_refresh.user_config_ui_view_name][this.config.auto_refresh.parameter] === 'true'
        }
      }
    }
  },

  beforeDestroy () {
    clearInterval(this.refreshInterval)
  },

  methods: {
    ...mapActionsBase({
      fetchBaseConfig: 'fetch'
    }),
    isEmpty,

    edit () {
      this.editClicked = true
    },

    updateItemObject (val) {
      this.itemObject = { ...val }
    },

    showDetailsActionButtonsHandler () {
      this.showDetailsActionButtons = true
      this.saveDetailsClicked = false
      this.cancelDetailsClicked = false
    },

    hideDetailsActionButtonsHandler () {
      this.showDetailsActionButtons = false
      this.saveDetailsClicked = false
      this.cancelDetailsClicked = false
    },

    resetButtonsClick () {
      this.saveDetailsClicked = false
      this.cancelDetailsClicked = false
    },

    async updateAutoRefresh (value) {
      try {
        if (this.config && this.config.auto_refresh && !isEmpty(this.config.auto_refresh) && this.config.auto_refresh.module && this.config.auto_refresh.route) {
          const formData = new FormData()
          if (this.config.auto_refresh.parameter) {
            formData.append(this.config.auto_refresh.parameter, value)
          }
          else {
            formData.append('auto_refresh', value)
          }
          await api()[this.config.auto_refresh.module].post(this.config.auto_refresh.route, formData)
          await this.fetchBaseConfig()
        }
      }
      catch (exception) {
        console.log('Error occurred when tried to update auto refresh config. Exception: ', exception)
      }
    },

    backFromDetails () {
      if (this.editClicked || this.showDetailsActionButtons) {
        this.showChangesNotSavedDialog = true
      }
      else if (this.redirectPath) {
        this.$router.replace(this.redirectPath)
        clearInterval(this.refreshInterval)
      }
      else {
        this.$router.go(-1)
        clearInterval(this.refreshInterval)
      }
    },

    confirmNotChangedDataExit () {
      this.$router.go(-1)
      clearInterval(this.refreshInterval)
      this.showChangesNotSavedDialog = false
    },

    setAutoRefresh (value) {
      clearInterval(this.refreshInterval)
      if (value) {
        if (this.config.auto_refresh && this.config.auto_refresh.refresh_every_seconds) {
          // Set a new interval for refreshing the page
          this.refreshInterval = setInterval(async () => {
            this.fetch(this.$route.params.id)
          }, this.config.auto_refresh.refresh_every_seconds * 1000)
        }
      }
    },

    async saveData () {
      try {
        if (!this.showDetailsActionButtons) {
          this.savingData = true
          const formData = { ...this.itemObject }

          await this.prepareAutocompleteValues(formData)
          await this.prepareFiles(formData)

          if (this.itemId) {
            await api()[this.module][this.method](this.route + '/' + this.itemId, formData)
          }
          this.editClicked = false
          this.currentTab = 0
          this.validationErrors = {}
          this.fetch(this.$route.params.id)
        }
        else {
          this.saveDetailsClicked = true
        }
      }
      catch (exception) {
        if (exception && exception.response && exception.response.data && exception.response.data.errors) {
          this.validationErrors = exception.response.data.errors
        }
      }
      finally {
        this.savingData = false
      }
    },

    cancelEdit () {
      if (!this.showDetailsActionButtons) {
        this.editClicked = false
        this.validationErrors = {}
        this.itemObject = cloneDeep(this.originalItemObject)
      }
      else {
        this.cancelDetailsClicked = true
      }
    },

    clearValidationErrors (key) {
      this.$delete(this.validationErrors, key)
    },

    // Prepare files before updating object (in case if image is path, then convert it to base64)
    async prepareFiles (formData) {
      const filesConfig = this.config.files_config
      if (this.config && filesConfig && !isEmpty(filesConfig)) {
        for (const fileKey in filesConfig) {
          // Prepare images for upload (can be extended the same logic for the other file types later, if needed)
          if (formData[fileKey] && Array.isArray(formData[fileKey]) && formData[fileKey].length && filesConfig[fileKey] && filesConfig[fileKey].type && filesConfig[fileKey].type === 'image') {
            const newFileArray = []

            for (const imageKey in formData[fileKey]) {
              if (formData[fileKey][imageKey] && typeof formData[fileKey][imageKey] === 'string') {
                const convertedToBlob = await convertFromPathToBlob(formData[fileKey][imageKey])
                const imagePathParts = formData[fileKey][imageKey].split('/')
                const existingImageName = imagePathParts[imagePathParts.length - 1]
                const file = new File([convertedToBlob], existingImageName, { type: convertedToBlob.type })
                const base64ImageFile = await convertToBase64(file)

                newFileArray.push({
                  data: base64ImageFile,
                  name: existingImageName
                })
              }
              else {
                newFileArray.push(formData[fileKey][imageKey])
              }
            }

            formData[fileKey] = newFileArray
          }
        }
      }
    },

    async prepareAutocompleteValues (formData) {
      const autocomplete = this.config.autocompletes_values

      if (autocomplete && !isEmpty(autocomplete)) {
        Object.entries(autocomplete).forEach(([autocompleteKey, config]) => {
          if (!(autocompleteKey in formData)) {
            return
          }

          const formDataValue = formData[autocompleteKey]
          const { type, value, objectPropertyContainingValue } = config

          if (type === 'object' && formDataValue && typeof formDataValue === 'object') {
            if (value in formDataValue) {
              formData[autocompleteKey] = formDataValue[value]
            }
          }
          else if (type === 'array' && Array.isArray(formDataValue)) {
            formData[autocompleteKey] = formDataValue.map(item => {
              if (item && typeof item === 'object' && objectPropertyContainingValue in item) {
                const nestedValue = item[objectPropertyContainingValue]
                if (typeof nestedValue === 'object' && value in nestedValue) {
                  return {
                    ...item,
                    [objectPropertyContainingValue]: nestedValue[value]
                  }
                }
              }
              return item
            })
          }
        })
      }
    },

    setValidationTabErrorIcon (tabIndex) {
      if (tabIndex && !this.validationErrorTabs.includes(tabIndex)) {
        this.validationErrorTabs.push(tabIndex)
      }
    },

    removeValidationTabErrorIcon (tabIndex) {
      const index = this.validationErrorTabs.indexOf(tabIndex)
      if (index > -1) {
        this.validationErrorTabs.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
.border-right {
  border-right: thin solid lightgray;
}

.border-bottom {
  border-bottom: thin solid lightgray;
}

.v-card {
  height: 100%;
}

.v-container {
  height: 100%;
}

.v-card-text {
  padding: 0;
}

.preview-button {
  margin-top: 10px;
}

.tabs-desktop {
  width: 100%;
}

.tabs-mobile {
  margin-left: 0;
}

.last-breadcrumbs-part {
  font-weight: bold;
}

.edit-mode {
  color: #333 !important;
}

::v-deep.autorefresh-checkbox .v-label {
    font-size: 0.8725rem !important;
}
::v-deep {
  .autorefresh-checkbox .v-label {
    font-size: 0.8725rem !important;
  }
  .v-card-text {
    line-height: unset !important;
  }
  .v-tabs--vertical > .v-tabs-bar .v-tab {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .v-overlay--active {
    background: rgba(241, 235, 235, 0.8);
  }
}
</style>
