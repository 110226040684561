<template>
  <v-container
    fluid
    class="px-0 py-0"
  >
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selectedItems"
          :error-messages="errorMessages"
          :hide-details="hideDetails"
          :items="internalItems"
          item-value="id"
          item-text="name"
          :label="label"
          :append-icon="appendIcon"
          chips
          clearable
          hide-selected
          multiple
          :outlined="outlined"
          :dense="dense"
          :solo="solo"
          :flat="flat"
          :readonly="readonly"
          :disabled="disabled"
        >
          <template #selection="{ attrs, item, parent, selectedValue }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :color="item.color"
              :input-value="selectedValue"
              label
              small
              class="mt-2"
            >
              <span class="pr-2">
                {{ item.name }}
              </span>
              <v-icon
                small
                @click="parent.selectItem(item)"
              >
                $delete
              </v-icon>
            </v-chip>
          </template>
          <template #append-item>
            <v-list-item
              v-if="hasMoreItems"
              class="has-more-items"
              @click="makeSearch('', true)"
            >
              <v-list-item-title class="font-italic text-decoration-underline">
                {{ $t('base.got_more_items') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { has, isEmpty } from 'lodash'
import { api } from '@/global/services/api'
import colors from 'vuetify/lib/util/colors'

export default {
  name: 'CustomCombobox',

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      default: () => {}
    },
    solo: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },
    errorMessages: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: ''
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    resetData: {
      type: Boolean,
      default: false
    },
    useRandomColors: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: String,
      default: null
    },
    initialTab: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      fetchingData: false,
      hasMoreItems: false,
      internalItems: []
    }
  },

  computed: {
    selectedItems: {
      get () {
        return this.value.map(item => ({
          id: !isNaN(item.id) ? parseInt(item.id) : item.id,
          name: item.name,
          color: item.color || this.pickRandomColor()
        }))
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  watch: {
    resetData (resetData) {
      if (resetData) {
        if (resetData) {
          this.selectedItems = []
        }
      }
    },
    items: {
      immediate: true,
      async handler (items) {
        if (this.field && this.field.key && items && !isEmpty(items) && this.tabIndex && this.tabIndex in items && items[this.tabIndex][this.field.key]) {
          this.internalItems = items[this.tabIndex][this.field.key].items.map(item => ({
            ...item,
            id: parseInt(item.id, 10),
            color: item.color ?? null
          }))
          this.hasMoreItems = items[this.tabIndex][this.field.key].hasMore ?? false
        }
      }
    },
    tabIndex: {
      immediate: true,
      async handler (val) {
        if (this.initialTab && this.initialTab === val) {
          if (this.items && !isEmpty(this.items) && !(val in this.items)) {
            await this.makeSearch()
          }
        }
        else {
          await this.makeSearch()
        }
      }
    }
  },

  methods: {
    async makeSearch (query = '', allItems = false) {
      try {
        this.fetchingData = true
        const params = {
          query: query,
          pickedId: null,
          includeAll: allItems
        }

        const { data, has_more: hasMore = false } = await api()[this.options.module].get(this.options.route, params)

        this.internalItems = data.map(item => ({
          id: !isNaN(item.id) ? parseInt(item.id) : item.id,
          name: item.name,
          color: item.color ?? null
        }))

        this.hasMoreItems = hasMore
      }
      catch (exception) {
        console.log('Exception occurred when tried to fetch combobox data in CustomCombobox component. Exception: ', exception)
      }
      finally {
        this.fetchingData = false
      }
    },

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    pickRandomColor () {
      const availableColors = [
        colors.red.base,
        colors.pink.base,
        colors.purple.base,
        colors.blue.base,
        colors.lightBlue.base,
        colors.cyan.base,
        colors.teal.base,
        colors.green.base,
        colors.lime.base,
        colors.amber.base,
        colors.orange.base,
        colors.deepOrange.base,
        colors.brown.base,
        colors.blueGrey.base,
        colors.grey.base
      ]

      const randomIndex = Math.floor(Math.random() * (availableColors.length - 1))

      return availableColors[randomIndex]
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
</style>
