<template>
  <v-dialog
    v-model="showDialog"
    :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '80%'"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title
        style="background-color: #eeeeee; font-size: 1.2rem;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-2"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-row
          >
          <span
            style="font-style: italic;"
            class="mt-5"
          >
            {{ dialogMessage }}
          </span>
          </v-row>
      </v-card-text>
      <v-card-actions
        style="background-color: #eeeeee;"
      >
        <v-spacer />
        <v-btn
          class="primary mr-3"
          text
          @click="closeDialog"
        >
          {{ $t('base.close') }}
        </v-btn>
        <v-btn
          class="green darken-3"
          style="color: white;"
          text
          @click="confirm"
        >
          {{ $t('base/patrol-vehicle.tips_form_location_modal_confirm_button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { api } from '@/global/services/api'
import { isEmpty } from 'lodash'

export default {
  name: 'ConfirmationDialog',

  props: {
    dialogMessage: {
      type: String,
      default: ''
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    apiConfig: {
      type: Object,
      default: null
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: Number,
      default: null
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.openDialog
      },
      set (val) {
        return val
      }
    }
  },

  methods: {
    closeDialog () {
      this.showDialog = false
      this.$emit('close-confirmation-dialog')
    },

    async confirm () {
      try {
        if (!isEmpty(this.apiConfig) && this.itemId) {
          if (!isEmpty(this.formData)) {
            const formData = this.getFormData()

            if (this.apiConfig._method) {
              formData.append('_method', this.apiConfig._method)
            }

            await api()[this.apiConfig.module][this.apiConfig.method](this.apiConfig.route + this.itemId, formData)
          }
          else {
            await api()[this.apiConfig.module][this.apiConfig.method](this.apiConfig.route + this.itemId)
          }

          this.showDialog = false
          this.$emit('close-confirmation-dialog', true)
        }
      }
      catch (exception) {
        console.log('Error occurred in ConfirmationDialog. Exception: ' + exception)
      }
    },

    getFormData () {
      const formData = new FormData()

      for (const key in this.formData) {
        if (typeof this.formData[key] === 'object' && this.formData[key] !== null && this.formData[key].id) {
          formData.append(key, this.formData[key].id)
        }
        else formData.append(key, this.formData[key] === null ? '' : this.formData[key])
      }

      return formData
    }
  }
}
</script>
