<template>
  <div>
    <v-autocomplete
      ref="input"
      v-model="selectedItem"
      :items="internalItems"
      item-value="id"
      :item-text="item => getItemText(item)"
      :label="getLabel()"
      :readonly="readonly"
      :disabled="disabled"
      :clearable="clearable && !readonly"
      :append-icon="appendIcon"
      :loading="fetchingData"
      :no-filter="noFilter"
      :outlined="outlined"
      :hide-details="getValidationErrors().length ? false : hideDetails"
      :error-messages="getValidationErrors()"
      :error-count="getValidationErrors().length"
      :autofocus="autofocus"
      return-object
      :dense="dense"
      :solo="solo"
      :flat="flat"
      @focus="autofocus && openMenuAutomatically ? simulateClick($event) : ''"
      @change="emitClearValidationError"
    >
      <template #append-item>
        <v-list-item
          v-if="hasMoreItems"
          class="has-more-items"
          @click="makeSearch('', true)"
        >
          <v-list-item-title class="font-italic text-decoration-underline">
            {{ $t('base.got_more_items') }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'CustomAutocomplete',

  props: {
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: '$dropdown'
    },
    value: {
      type: [Number, String, Object],
      default: null
    },
    field: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    noFilter: {
      type: Boolean,
      default: false
    },
    mainObject: {
      type: Object,
      default: () => {}
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    openMenuAutomatically: {
      type: Boolean,
      default: false
    },
    shouldSetTabValidationErrors: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: String,
      default: null
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    collectionObjectIndex: {
      type: String,
      default: null
    },
    collectionName: {
      type: String,
      default: null
    },
    initialTab: {
      type: String,
      default: ''
    }
  },

  emits: [
    'input',
    'edit-clicked',
    'clear-validation-errors',
    'set-tab-validation-error-icon',
    'remove-tab-validation-error-icon'
  ],

  data () {
    return {
      fetchingData: false,
      hasMoreItems: false,
      internalItems: []
    }
  },

  computed: {
    selectedItem: {
      get () {
        if (this.value && this.value.id && this.internalItems.length) {
          const found = this.internalItems.some(item => item.id === this.value.id)
          if (!found) {
            this.makeSearch()
          }
        }
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  watch: {
    validationErrors: {
      immediate: true,
      deep: true,
      handler (errors) {
        if (this.shouldSetTabValidationErrors) {
          if (errors && !isEmpty(errors)) {
            if (this.field && this.field.key && Object.prototype.hasOwnProperty.call(errors, this.field.key)) {
              this.$emit('set-tab-validation-error-icon', this.tabIndex)
            }
          }
          else {
            this.$emit('remove-tab-validation-error-icon', this.tabIndex)
          }
        }
      }
    },
    items: {
      immediate: true,
      async handler (items) {
        if (this.field && this.field.key && items && !isEmpty(items) && this.tabIndex && this.tabIndex in items && items[this.tabIndex][this.field.key]) {
          this.internalItems = items[this.tabIndex][this.field.key].items.map(item => ({
            ...item,
            id: parseInt(item.id, 10)
          }))
          this.hasMoreItems = items[this.tabIndex][this.field.key].hasMore ?? false
        }
      }
    },
    tabIndex: {
      immediate: true,
      async handler (val) {
        if (this.initialTab && this.initialTab === val) {
          if (this.items && !isEmpty(this.items) && !(val in this.items)) {
            await this.makeSearch()
          }
        }
        else {
          await this.makeSearch()
        }
      }
    }
  },

  methods: {
    isEmpty,

    async makeSearch (query = '', allItems = false) {
      try {
        this.fetchingData = true
        const pickedId = typeof this.value === 'object' ? this.value?.id : this.value

        const params = {
          query: query,
          pickedId: pickedId,
          includeAll: allItems,
          modelId: this.$route.params.id
        }

        if (this.mainObject && !isEmpty(this.mainObject) && this.options && this.options.depends_on) {
          const option = this.options.states[this.mainObject[this.options.depends_on]]
          const { data, has_more: hasMore = false } = await api()[option.module].get(option.route, params)

          this.internalItems = data.map(item => ({
            ...item,
            id: parseInt(item.id, 10)
          }))

          this.hasMoreItems = hasMore
        }
        else {
          const { data, has_more: hasMore = false } = await api()[this.options.module].get(this.options.route, params)

          this.internalItems = data.map(item => ({
            ...item,
            id: parseInt(item.id, 10)
          }))

          this.hasMoreItems = hasMore
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to fetch autocomplete data. Exception: ', exception)
      }
      finally {
        this.fetchingData = false
      }
    },

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    simulateClick (event) {
      setTimeout(() => {
        event.target.click()
        const inputElement = document.activeElement
        if (event.target.value && event.target.value !== '' && inputElement && typeof inputElement.setSelectionRange === 'function') {
          const textLength = event.target.value.length
          inputElement.setSelectionRange(textLength, textLength)
        }
      }, 240)
    },

    emitClearValidationError () {
      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors')
      }

      if (this.shouldSetTabValidationErrors) {
        this.$emit('remove-tab-validation-error-icon', this.tabIndex)
      }
    },

    getLabel () {
      if (!this.field) {
        return ''
      }

      // Use label for text field only when specified
      if (this.useLabel) {
        return this.field.label
      }

      return this.field.text_label || ''
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    },

    getItemText (item) {
      let returnValue = item.name ?? ''

      if (this.options && this.options.custom_display_value) {
        returnValue = this.options.custom_display_value(item)
      }

      return returnValue
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else if (this.collectionObjectIndex && this.collectionName) {
          const formattedCollectionKey = this.collectionName + '.' + this.collectionObjectIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }

      return messages
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-select__slot > input {
    color: #777474;
    font-weight: bold;
  }
  .v-input > .v-input__control > .v-input__slot > .v-select__slot > input, label {
    font-size: 0.8125rem;
  }
}
</style>
