<template>
  <div>
    <!--BREADCRUMBS PART-->
    <v-row
      class="border-bottom"
    >
      <v-col
        class="d-flex justify-start"
      >
        <span
          style="color: var(--v-primary-base); font-size: 0.8725rem;"
        >
          <span>
            {{ tabItem.label }}
          </span>
          <span
            style="padding-left: 7px;"
          >
            >
          </span>
          <span
            style="font-weight: bold; padding-left: 7px;"
          >
            {{ $t('fleet/vehicle.details') }}
          </span>
        </span>
      </v-col>
    </v-row>

    <!--DETAILS FORM-->
    <v-row
      class="mt-5"
    >
      <v-col
        v-for="(detailsColumn, detailsColumnIndex) in column.detailsConfig.columns"
        :key="detailsColumnIndex"
        :cols="column.detailsConfig.columns.length === 1 ? 6 : ''"
      >
        <div
          v-for="(field, fieldIndex) in detailsColumn.items"
          :key="fieldIndex"
        >
          <v-row>
            <v-col
              cols="4"
            >
              <span
                v-if="field.type === 'dependable'"
                style="color: rgba(0, 0, 0, 0.54); font-weight: bold;"
              >
                {{ detailsObject[field.depends_on] ? field.label : '' }}
              </span>
              <span
                v-else
                style="color: rgba(0, 0, 0, 0.54); font-weight: bold; font-size: 0.8125rem;"
              >
                {{ getFieldLabel(field) }}
              </span>
            </v-col>
            <v-col
              cols="6"
              style="padding: 0;"
              :class="getColumnClass(field)"
            >
              <!--Text or number field-->
              <div
                v-if="field.type === 'text'"
                style="width: 100%; position: relative;"
              >
                <custom-text-field
                  v-model="detailsObject[field.key]"
                  :field="field"
                  :not-use-label="true"
                  :validation-errors="validationErrors"
                  @clear-validation-errors="clearValidationErrors(field.key)"
                />
              </div>

              <!--Autocomplete field-->
              <div
                v-if="field.type === 'autocomplete'"
                style="width: 100%; position: relative;"
              >
                <custom-autocomplete
                  v-model="detailsObject[field.key]"
                  :options="field.autocomplete_options"
                  :dense="true"
                  :field="field"
                  :main-object="mainObject"
                  :hide-details="true"
                  label=""
                  :validation-errors="validationErrors"
                  @clear-validation-errors="clearValidationErrors(field.key)"
                />
              </div>

              <!--Combobox field-->
              <div
                v-if="field.type === 'combobox'"
                style="width: 100%; position:relative;"
              >
                <custom-combobox
                  v-model="detailsObject[field.key]"
                  :options="field.autocomplete_options"
                  :selected="detailsObject[field.key]"
                  :use-random-colors="true"
                  :field="field"
                  :dense="true"
                  :flat="true"
                  :hide-details="true"
                />
              </div>

              <!--Date picker field-->
              <div
                v-if="field.type === 'date'"
                style="width: 100%; position:relative;"
              >
                <custom-date-picker
                  v-model="detailsObject[field.key]"
                  :validation-errors="validationErrors"
                  :field="field"
                  :not-use-label="true"
                  :hide-details="true"
                  @clear-validation-errors="clearValidationErrors(field.key)"
                />
              </div>

              <!--Image upload field-->
              <div
                v-if="field.type === 'image_upload'"
                style="width: 100%; position:relative;"
              >
                <v-file-input
                  ref="imageInput"
                  v-model="detailsImages"
                  class="mt-0"
                  accept="image/*"
                  counter
                  multiple
                  small-chips
                  prepend-icon="mdi-camera"
                  dense
                  truncate-length="20"
                  @change="files => onImageInput(files, field)"
                />
              </div>

              <!--Pdf upload field-->
              <div
                v-if="field.type === 'pdf_upload'"
                style="width: 100%; position:relative;"
              >
                <v-file-input
                  v-model="detailsPdfs"
                  class="mt-0"
                  accept="application/pdf"
                  prepend-icon="mdi-file-pdf-box"
                  counter
                  multiple
                  small-chips
                  dense
                  truncate-length="20"
                  @change="files => onPdfInput(files, field)"
                />
              </div>

              <!--Switch field-->
              <div
                v-if="field.type === 'switch'"
              >
                <custom-switch
                  v-if="field.type === 'switch'"
                  v-model="detailsObject[field.key]"
                  :field="field"
                  :hide-details="true"
                />
              </div>

              <!--Icon image field-->
              <div
                v-if="field.type === 'image'"
              >
                <v-img
                  v-if="detailsObject[field.key]"
                  :src="detailsObject[field.key] ? detailsObject[field.key] : field.base_image_src"
                  alt="No status icon"
                />
              </div>

              <!--Images preview in dialog-->
              <div
                v-if="field.type === 'images_preview' && detailsObject[field.key] && Object.keys(detailsObject[field.key]).length"
              >
                <v-badge
                  :content="detailsObject[field.key] && Object.keys(detailsObject[field.key]).length ? Object.keys(detailsObject[field.key]).length.toString() : '0'"
                  :color="'#f3d187'"
                  overlap
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon
                    @click.stop="showImagesPreviewDialog(detailsObject[field.key], detailsObject, field.key)"
                  >
                    mdi-image-multiple-outline
                  </v-icon>
                </v-badge>

                <images-preview-dialog
                  :show-dialog="canShowImagesPreviewDialog"
                  :images="detailsObject[field.key]"
                  :field="field"
                  :dialog-title="imagesPreviewDialogTitle"
                  @close-images-preview-dialog="canShowImagesPreviewDialog = false"
                  @updated-images="newImages => updatedImages(newImages, field.key)"
                  @refresh-table="refreshTable"
                />
              </div>

              <!--Pdf preview in dialog-->
              <div
                v-if="field.type === 'pdf_preview' && detailsObject[field.key] && Object.keys(detailsObject[field.key]).length"
              >
                <v-badge
                  :content="detailsObject[field.key] && Object.keys(detailsObject[field.key]).length ? Object.keys(detailsObject[field.key]).length.toString() : '0'"
                  :color="'#f3d187'"
                  overlap
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon
                    @click.stop="showPdfPreviewDialog(detailsObject[field.key], detailsObject, field.key)"
                  >
                    mdi-file-document-outline
                  </v-icon>
                </v-badge>

                <pdfs-preview-dialog
                  :show-dialog="canShowPdfPreviewDialog"
                  :pdfs="detailsObject[field.key]"
                  :field="field"
                  :dialog-title="pdfsPreviewDialogTitle"
                  @updated-pdfs="newPdfs => updatedPdfs(newPdfs, field.key)"
                  @close-pdfs-preview-dialog="canShowPdfPreviewDialog = false"
                  @refresh-table="refreshTable"
                />
              </div>

              <!--Dependable field (showing field that depends on the other field/s)-->
              <div
                v-if="field.type === 'dependable' && detailsObject[field.depends_on]"
              >
                <custom-text-field
                  v-if="shouldShowDependableField(field, 'text')"
                  v-model="detailsObject[field.key]"
                  :field="field.states[detailsObject[field.depends_on][field.value]]"
                  :suffix="field.states[detailsObject[field.depends_on][field.value]].suffix"
                />
                <custom-date-picker
                  v-if="shouldShowDependableField(field, 'date')"
                  v-model="detailsObject[field.key]"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { api } from '@/global/services/api'
import { cloneDeep, forEach, isEmpty } from 'lodash'
import ImagesPreviewDialog from '../../dialogs/ImagesPreviewDialog.vue'
import CustomCombobox from '../../custom-fields/CustomCombobox.vue'
import CustomAutocomplete from '../../custom-fields/CustomAutocomplete.vue'
import CustomTextField from '../../custom-fields/CustomTextField.vue'
import CustomDatePicker from '../../custom-fields/CustomDatePicker.vue'
import CustomSwitch from '../../custom-fields/CustomSwitch.vue'
import PdfsPreviewDialog from '../../dialogs/PdfPreviewDialog.vue'
import store from '@/global/store'

export default {
  name: 'DataTableRowDetails',

  components: {
    PdfsPreviewDialog,
    CustomCombobox,
    CustomAutocomplete,
    CustomTextField,
    CustomDatePicker,
    ImagesPreviewDialog,
    CustomSwitch
  },

  props: {
    column: {
      type: Object,
      default: () => {}
    },
    editObject: {
      type: Object,
      default: () => {}
    },
    mainObject: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: String,
      default: ''
    },
    tabItem: {
      type: Object,
      default: () => {}
    },
    isCreatingDetail: {
      type: Boolean,
      default: false
    },
    currentDetailId: {
      type: Number,
      default: null
    },
    saveDetailsClicked: {
      type: Boolean,
      default: false
    },
    cancelDetailsClicked: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'set-tab-validation-error-icon',
    'remove-tab-validation-error-icon',
    'back-from-details',
    'refresh-table',
    'reset-buttons-click'
  ],

  data () {
    return {
      detailsObject: {},
      detailsImages: [],
      detailsPdfs: [],
      canShowImagesPreviewDialog: false,
      canShowPdfPreviewDialog: false,
      validationErrors: {},
      imageInputKey: 0,
      imagesPreviewDialogTitle: '',
      pdfsPreviewDialogTitle: ''
    }
  },

  watch: {
    editObject: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          this.detailsObject = cloneDeep(val)
        }
      }
    },

    validationErrors: {
      immediate: true,
      deep: true,
      handler (errors) {
        if (errors && !isEmpty(errors)) {
          this.$emit('set-tab-validation-error-icon', this.tabIndex)
        }
        else {
          this.$emit('remove-tab-validation-error-icon', this.tabIndex)
        }
      }
    },
    saveDetailsClicked: {
      async handler (val) {
        if (val) {
          await this.saveDetails(this.column.detailsConfig)
        }
      }
    },

    cancelDetailsClicked: {
      handler (val) {
        if (val) {
          this.backFromDetails()
        }
      }
    }
  },

  methods: {
    async saveDetails (config) {
      try {
        if (config && config.apiConfig && config.apiConfig.parent_id_name) {
          this.$set(this.detailsObject, config.apiConfig.parent_id_name, this.$route.params.id)
          const formData = this.getDetailsFormData()
          if (this.isCreatingDetail) {
            await api()[config.apiConfig.module].post(config.apiConfig.route, formData)
          }
          else if (this.currentDetailId) {
            formData.append('_method', 'PATCH')
            await api()[config.apiConfig.module].post(config.apiConfig.route + '/' + this.currentDetailId, formData)
          }
        }
        this.$emit('back-from-details', true)
      }
      catch (exception) {
        this.$emit('reset-buttons-click')
        console.log('Exception occurred when tried to save row detail page. Exception: ', exception)
        if (exception && exception.response && exception.response.data && exception.response.data.errors) {
          this.validationErrors = exception.response.data.errors
        }
      }
    },

    backFromDetails () {
      this.validationErrors = {}
      this.$emit('back-from-details')
      this.$emit('remove-tab-validation-error-icon', this.tabIndex)
    },

    getDetailsFormData () {
      const formData = new FormData()

      for (const key in this.detailsObject) {
        if (typeof this.detailsObject[key] === 'object' && this.detailsObject[key] !== null && this.detailsObject[key].id) {
          formData.append(key, this.detailsObject[key].id)
        }
        else if (key === 'status' || key === 'alarm_on') {
          if (this.detailsObject[key]) {
            formData.append(key, '1')
          }
          else {
            formData.append(key, '0')
          }
        }
        else formData.append(key, this.detailsObject[key] === null ? '' : this.detailsObject[key])
      }

      if (this.detailsImages.length) {
        this.detailsImages.forEach(image => {
          formData.append('images[]', image)
        })
      }

      if (this.detailsPdfs.length) {
        this.detailsPdfs.forEach(image => {
          formData.append('pdf[]', image)
        })
      }

      return formData
    },

    showImagesPreviewDialog (canShow, item, key) {
      this.canShowImagesPreviewDialog = !!canShow
      this.imagesPreviewDialogTitle = this.column && this.column.display_data_config && this.column.display_data_config[key] && this.column.display_data_config[key].preview_dialog_title ? this.column.display_data_config[key].preview_dialog_title(item) : ''
    },

    showPdfPreviewDialog (canShow, item, key) {
      this.canShowPdfPreviewDialog = !!canShow
      this.pdfsPreviewDialogTitle = this.column && this.column.display_data_config && this.column.display_data_config[key] && this.column.display_data_config[key].preview_dialog_title ? this.column.display_data_config[key].preview_dialog_title(item) : ''
    },

    getColumnClass (field) {
      if (field.type === 'images_preview' || field.type === 'pdf_preview') {
        return 'd-flex align-center justify-start'
      }
      else {
        return 'mb-5'
      }
    },

    shouldShowDependableField (field, type = '') {
      if (isEmpty(field) || type.trim() === '' || !field.states) {
        return false
      }

      const dependency = field.depends_on
      const dependencyState = this.detailsObject[dependency]

      if (!dependencyState) {
        return false
      }

      if (field.value) {
        return field.states[dependencyState[field.value]]?.type === type
      }

      return field.states[dependencyState].type === type
    },

    clearValidationErrors (key) {
      this.$delete(this.validationErrors, key)
    },

    updatedImages (newImages, key) {
      this.detailsObject[key] = newImages
    },

    updatedPdfs (newPdfs, key) {
      this.detailsObject[key] = newPdfs
    },

    onImageInput (files, field) {
      const uploadedImages = files
      let invalidMessages = ''

      // Checking whether the number of uploaded files exceeds the limit
      if (uploadedImages.length > field.max) {
        store.dispatch('base/notifications/push', this.$t('fleet/vehicle.maximum_images_uploaded').replace(':max-images', field.max))
        this.detailsImages = []
        return
      }

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedImages, uploadedImage => {
        if (!uploadedImage.type.startsWith('image/')) {
          invalidMessages += this.$t('fleet/vehicle.not_valid_image_format_message').replace(':image', uploadedImage.name)
        }
      })

      if (invalidMessages) {
        store.dispatch('base/notifications/push', invalidMessages)
        this.detailsImages = []
      }
    },

    onPdfInput (files, field) {
      const uploadedPdfFiles = files
      let invalidMessages = ''

      // Checking whether the number of uploaded files exceeds the limit
      if (uploadedPdfFiles.length > field.max) {
        store.dispatch('base/notifications/push', this.$t('fleet/vehicle.maximum_pdf_uploaded').replace(':max-pdf-files', field.max))
        this.detailsPdfs = []
        return
      }

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedPdfFiles, uploadedImage => {
        if (!uploadedImage.type.startsWith('application/pdf')) {
          invalidMessages += this.$t('fleet/vehicle.not_valid_pdf_format_message').replace(':pdf', uploadedImage.name)
        }
      })

      if (invalidMessages) {
        store.dispatch('base/notifications/push', invalidMessages)
        this.detailsPdfs = []
      }
    },

    refreshTable () {
      this.$emit('refresh-table')
    },

    getFieldLabel (field) {
      if (!field || !field.key) {
        return ''
      }

      if ((field.type === 'images_preview' || field.type === 'pdf_preview') && (!this.detailsObject[field.key] || !Object.keys(this.detailsObject[field.key]).length)) {
        return ''
      }

      return field.label
    }
  }
}
</script>

<style scoped>
.border-bottom {
  border-bottom: thin solid lightgray;
}
</style>
