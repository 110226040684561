<template>
  <div>
    <div>
      <v-row
      >
        <v-col
          cols="auto"
          class="d-flex justify-end align-end"
        >
          <v-btn
            icon
            @click="openDataTableFilterDialog"
          >
            <v-icon
              style="font-size: 1.6rem;"
            >
              mdi-filter-variant
            </v-icon>
          </v-btn>
        </v-col>
        <!--Display chosen filters-->
        <v-col>
          <v-chip-group
            v-model="activeFilters"
            column
            multiple
          >
            <v-chip
              v-for="filterKey in Object.keys(chosenFilters)"
              :key="filterKey"
              :input-value="chosenFilters[filterKey]"
              :value="filterKey"
              active-class="primary--text"
              filter
              outlined
              :close="!!chosenFilters[filterKey]"
              @input="status => changeFilterActivityStatus(filterKey, status)"
              @click:close="removeFilterChip(filterKey)"
            >
              <span>{{ getChipValue(filterKey) }}</span>
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </div>

    <data-table-filter-dialog
      :show-dialog="showFiltersDialog"
      :dialog-title="column.filters_dialog_title ? column.filters_dialog_title : 'Filteri'"
      :column="column"
      :main-object="mainObject"
      :remove-filters-form-fields="filtersFormFieldsToRemove"
      @apply-filters="applyFilters"
      @filters-form-fields-removed="updateFiltersFormFieldsToRemove"
    />
  </div>
</template>

<script>
import DataTableFilterDialog from '../../dialogs/DataTableFilterDialog.vue'
import { cloneDeep, isEmpty, isObject } from 'lodash'

export default {
  name: 'DataTableFilters',

  components: {
    DataTableFilterDialog
  },

  props: {
    column: {
      type: Object,
      required: true,
      default: () => {}
    },
    mainObject: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      showFiltersDialog: false,
      chosenFilters: {},
      activeFilters: [],
      filtersFormFieldsToRemove: [],
      toggleFilters: {}
    }
  },

  watch: {
    chosenFilters: {
      deep: true,
      handler (filters) {
        if (filters && !isEmpty(filters)) {
          Object.keys(filters).map(key => {
            if (filters[key] && filters[key] !== '') {
              if (!this.activeFilters.includes(key)) {
                this.activeFilters.push(key)
                this.toggleFilters[key] = true
              }
            }
          })
        }
      }
    }
  },

  methods: {
    openDataTableFilterDialog () {
      this.showFiltersDialog = true
    },

    applyFilters (filters, rememberFilters = true) {
      this.showFiltersDialog = false
      const modifiedFilters = {}

      if (filters && !isEmpty(filters)) {
        if (rememberFilters) {
          this.chosenFilters = filters
        }

        Object.keys(filters).forEach(key => {
          const itemKeyPart = key.split('|')[0]
          if (!filters[key] || !itemKeyPart) {
            return
          }

          modifiedFilters[itemKeyPart] = filters[key]
        })
      }
      this.$emit('filtered-table-items', modifiedFilters)
    },

    removeFilterChip (filterKey) {
      this.$delete(this.chosenFilters, filterKey)
      this.$delete(this.toggleFilters, filterKey)
      if (!this.filtersFormFieldsToRemove.includes(filterKey)) {
        this.filtersFormFieldsToRemove.push(filterKey)
      }
      this.applyFilters(this.chosenFilters)
    },

    changeFilterActivityStatus (filterKey, status) {
      this.toggleFilters[filterKey] = status
      const modifiedFilters = cloneDeep(this.chosenFilters)

      const isAnyFilterActive = Object.keys(this.toggleFilters).reduce((active, key) => {
        if (!this.toggleFilters[key]) {
          delete modifiedFilters[key]
        }
        return active || this.toggleFilters[key]
      }, false)

      if (!isAnyFilterActive) {
        this.chosenFilters = []
        Object.keys(this.toggleFilters).forEach(key => {
          if (!this.filtersFormFieldsToRemove.includes(key)) {
            this.filtersFormFieldsToRemove.push(key)
          }
        })

        this.applyFilters(this.chosenFilters)
      }
      else {
        this.applyFilters(modifiedFilters, false)
      }
    },

    updateFiltersFormFieldsToRemove (filterKey) {
      const index = this.filtersFormFieldsToRemove.indexOf(filterKey)
      if (index > -1) {
        this.filtersFormFieldsToRemove.splice(index, 1)
      }
      this.$delete(this.toggleFilters, filterKey)
    },

    getChipValue (filterKey) {
      if (!filterKey || !this.chosenFilters[filterKey]) {
        return ''
      }

      const [, label] = filterKey.split('|')

      if (!label) {
        return ''
      }

      const filterValue = this.chosenFilters[filterKey]
      const name = isObject(filterValue) && filterValue.name ? filterValue.name : filterValue

      return `${label}: ${name}`
    }
  }
}
</script>
