<template>
  <v-switch
    v-model="selectedValue"
    style="width: 3rem; height: 1rem;"
    class="d-flex align-center"
    :color="color"
    hide-details
    :disabled="disabled"
  />
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'CustomSwitch',

  props: {
    color: {
      type: String,
      default: 'primary'
    },

    dense: {
      type: Boolean,
      default: true
    },

    solo: {
      type: Boolean,
      default: false
    },

    editClicked: {
      type: Boolean,
      default: false
    },

    flat: {
      type: Boolean,
      default: false
    },

    value: {
      type: [Boolean, Number],
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    field: {
      type: Object,
      default: () => {}
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    hideDetails: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selectedValue: {
      get () {
        return this.value
      },
      async set (newVal) {
        if (this.field && this.field.callBackend && !isEmpty(this.field.apiConfig) && this.field.apiConfig.module && this.field.apiConfig.route) {
          try {
            await api()[this.field.apiConfig.module][this.field.apiConfig.method](this.field.apiConfig.route)
          }
          catch (exception) {
            console.log('Exception occurred when tried to send an api call in CustomSwitch component. Exception: ', exception)
          }
        }
        this.$emit('input', newVal)
      }
    }
  },

  methods: {
    edit (field) {
      this.$emit('edit-clicked', field)
    }
  }
}
</script>
