<template>
  <v-btn
    :class="buttonClass"
    :color="colorComputed"
    :outlined="outlined"
    @click="$emit('click', type)"
  >
    <v-icon
      v-if="prependIconComputed"
      :class="prependIconClassComputed"
    >
      {{ prependIconComputed }}
    </v-icon>

    <span
      style="font-size: 0.6125rem;"
    >
      {{ labelComputed ? labelComputed : label }}
    </span>

    <v-icon
      v-if="appendIconComputed"
    >
      {{ appendIconComputed }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'Button',

  props: {
    type: {
      type: String,
      default: 'save'
    },
    label: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    prependIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      buttonClass: 'primary',
      appendIconComputed: '',
      prependIconComputed: '',
      labelComputed: '',
      colorComputed: '',
      prependIconClassComputed: ''
    }
  },

  watch: {
    type: {
      immediate: true,
      handler (type) {
        this.clearComputedVariables()
        switch (type) {
          case 'back':
            this.prependIconComputed = 'mdi-arrow-left-bold'
            if (!this.label) {
              this.labelComputed = this.$t('base.back')
            }
            break
          case 'edit':
            this.prependIconComputed = 'mdi-pencil'
            if (!this.label) {
              this.labelComputed = this.$t('base.edit')
            }
            break
          case 'cancel':
            this.colorComputed = '#a1384d'
            this.buttonClass = 'edit-btn'
            this.prependIconComputed = 'mdi-file-remove'
            if (!this.label) {
              this.labelComputed = this.$t('base.cancel')
            }
            break
          case 'create':
            this.prependIconComputed = 'mdi mdi-plus-circle'
            this.prependIconClassComputed = 'success--text mr-3'
            this.buttonClass = ''
            if (!this.label) {
              this.labelComputed = this.$t('base.cancel')
            }
            break
          default:
            this.prependIconComputed = 'mdi-content-save'
            this.colorComputed = '#4e804e'
            this.buttonClass = 'save-btn'
            if (!this.label) {
              this.labelComputed = this.$t('base.save')
            }
        }
      }
    }
  },

  methods: {
    clearComputedVariables () {
      this.prependIconComputed = ''
      this.appendIconComputed = ''
      this.buttonClass = 'primary'
      this.colorComputed = ''
      this.labelComputed = ''
      this.prependIconClassComputed = ''
    }
  }
}
</script>

<style scoped>
.edit-btn {
  color: white;
}
.save-btn {
  color: white;
}
</style>
