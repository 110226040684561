<template>
  <div>
    <v-overlay
      v-if="isTableLoading"
      style="left: 12px; bottom: 12px"
      opacity="-0.2"
      absolute
    >
      <v-progress-circular
        indeterminate
        :color="'var(--v-primary-base)'"
        size="60"
      />
    </v-overlay>
    <!--    ROW DETAILS-->
    <div
      v-if="shouldShowDetails && column.detailsConfig"
    >
      <data-table-row-details
        :column="column"
        :tab-index="tabIndex"
        :tab-item="tabItem"
        :edit-object="editObject"
        :main-object="itemObject"
        :is-creating-detail="isCreatingDetail"
        :current-detail-id="currentDetailId"
        :save-details-clicked="saveDetailsClicked"
        :cancel-details-clicked="cancelDetailsClicked"
        @back-from-details="backFromDetails"
        @refresh-table="fetchTableData"
        @set-tab-validation-error-icon="validationTabIndex => $emit('set-tab-validation-error-icon', validationTabIndex)"
        @remove-tab-validation-error-icon="validationTabIndex => $emit('remove-tab-validation-error-icon', validationTabIndex)"
        @reset-buttons-click="$emit('reset-buttons-click')"
      />
    </div>
    <!--    DATA TABLE-->
    <div
      v-else
    >
      <!--Filters toolbar-->
      <v-row
        v-if="column && column.table_filters && column.table_filters.length"
        flat
        dense
        class="mb-1"
      >
        <v-col>
          <data-table-filters
            :column="column"
            :main-object="itemObject"
            @filtered-table-items="filterTableItems"
          />
        </v-col>

        <v-col
          v-for="(button, buttonIndex) in column.buttons"
          :key="buttonIndex"
          class="d-flex justify-end"
        >
          <Button
            :type="'create'"
            :label="$t('base.add_new_row')"
            outlined
            @click="createNewDetail"
          />
        </v-col>
      </v-row>

      <!--Data table-->
      <v-row
        no-gutters
      >
        <v-col>
          <v-data-table
            :headers="!isEmpty(column) && column.headers ? column.headers : []"
            :items="tableItems"
            :class="{ 'row-pointer': canEdit }"
            height="66vh"
            :loading="isTableLoading"
            :items-per-page="pagination.perPage"
            :server-items-length="pagination.total"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: column.pagination_numbers ? column.pagination_numbers:  []
            }"
            @update:items-per-page="fetchTableData"
          >
            <template
              #item="{ item }"
            >
              <tr
                @click="tableRowClick(item)"
              >
                <td
                  v-for="header in column.headers"
                  :key="header.value"
                  :class="getCellClass(header.value) ? 'td-text-center' : 'td-text-left'"
                >
                  <!--Custom data table values-->
                  <span
                    v-if="column.display_data_config[header.value]"
                  >
                    <template
                      v-if="column.display_data_config[header.value].type === 'object'"
                    >
                      <div
                        v-if="column.display_data_config[header.value].display_value"
                      >
                        <span
                          v-if="column.display_data_config[header.value].use_translation"
                        >
                          {{ $t(item[header.value][column.display_data_config[header.value].display_value]) }}
                        </span>
                        <span
                          v-else
                        >
                          {{ item[header.value][column.display_data_config[header.value].display_value] }}
                        </span>
                      </div>
                      <div
                        v-else
                      >
                        <span>
                          {{ item[header.value][column.display_data_config[header.value].name] }}
                        </span>
                      </div>
                    </template>
                    <template
                      v-else-if="column.display_data_config[header.value].type === 'image'"
                    >
                      <v-tooltip
                        top
                      >
                        <template
                          #activator="{ on, attrs }"
                        >
                          <div
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="showImagesPreviewDialog(item[header.value], header.value, item)"
                          >
                            <v-badge
                              :content="Object.keys(item[header.value]).length"
                              :color="Object.keys(item[header.value]).length ? '#f3d187' : ''"
                              overlap
                              offset-x="10"
                              offset-y="10"
                            >
                              <v-icon
                                class="mr-2"
                                style="color: var(--v-primary-base);"
                              >
                                mdi-image-multiple-outline
                              </v-icon>
                            </v-badge>
                          </div>
                        </template>
                        <span>
                          {{ $t('base/patrol-vehicle.tips_form_click_for_to_see_images') }}
                        </span>
                      </v-tooltip>
                    </template>
                    <template
                      v-else-if="column.display_data_config[header.value].type === 'pdf'"
                    >
                      <v-tooltip
                        top
                      >
                        <template
                          #activator="{ on, attrs }"
                        >
                          <div
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="showPdfFiles(item[header.value], header.value, item)"
                          >
                            <v-badge
                              :content="Object.keys(item[header.value]).length"
                              :color="Object.keys(item[header.value]).length ? '#f3d187' : ''"
                              overlap
                              offset-x="10"
                              offset-y="10"
                            >
                              <v-icon
                                class="mr-2"
                                style="color: var(--v-primary-base);"
                              >
                                mdi-file-document-outline
                              </v-icon>
                            </v-badge>
                          </div>
                        </template>
                        <span> {{ $t('fleet/vehicle.see_pdfs') }}</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-else-if="column.display_data_config[header.value].type === 'custom_value'"
                    >
                      {{ column.display_data_config[header.value].replace_values[item[header.value]] }}
                    </template>
                    <template
                      v-else-if="column.display_data_config[header.value].type === 'custom_html'"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <span
                        v-html="getCellContent(header.value, item)"
                      />
                    </template>
                    <template
                      v-else-if="column.display_data_config[header.value].type === 'boolean'"
                    >
                      <v-icon
                        v-if="item[header.value]"
                        color="success"
                        class="pb-2"
                        small
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-icon
                        v-else
                        small
                        color="red"
                        class="pb-2"
                      >
                        mdi-close-circle
                      </v-icon>
                    </template>
                    <template
                      v-else
                    >
                      {{ item[header.value] }}
                    </template>
                  </span>
                  <!--Actions-->
                  <span
                    v-else
                  >
                    <template
                      v-if="header.value === 'actions'"
                    >
                      <v-menu
                        offset-y
                        offset-overflow
                        left
                      >
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-dots-vertical
                          </v-icon>
                        </template>

                        <v-list
                          v-for="(action, actionIndex) in column.actions"
                          :key="actionIndex"
                          class="pa-0"
                        >
                          <v-list-item
                            v-if="(action.key === 'activate' && !item[action.parameter]) || (action.key === 'deactivate' && item[action.parameter]) || (action.key !== 'activate' && action.key !== 'deactivate')"
                            class="d-flex align-center list-item-min-height"
                            link
                            @click="tableActionClick(action, item)"
                          >
                            <v-list-item-icon class="align-self-center my-0 mr-2">
                              <v-icon
                                small
                              >
                                {{ action.icon }}
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-title class="main-font-size">
                              {{ action.text }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    {{ item[header.value] }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
          <images-preview-dialog
            v-if="canShowImagesPreviewDialog"
            :show-dialog="canShowImagesPreviewDialog"
            :images="imagesToShow"
            :field="imagesConfig"
            :dialog-title="imagesPreviewDialogTitle"
            :show-actions="false"
            @close-images-preview-dialog="canShowImagesPreviewDialog = false"
          />

          <pdfs-preview-dialog
            v-if="canShowPdfsPreviewDialog"
            :show-dialog="canShowPdfsPreviewDialog"
            :pdfs="pdfsToShow"
            :field="pdfConfig"
            :dialog-title="pdfsPreviewDialogTitle"
            :show-actions="false"
            @close-pdfs-preview-dialog="canShowPdfsPreviewDialog = false"
          />

          <form-data-dialog
            v-if="canShowFormDataDialog"
            :open-dialog="canShowFormDataDialog"
            :dialog-message="dialogMessage"
            :dialog-title="dialogTitle"
            :dialog-fields="dialogFields"
            :item-id="dialogId"
            :row-item="dialogRowItem"
            :api-config="dialogApiConfig"
            @close-form-data-dialog="backFromFormDataDialog"
          />

          <confirmation-dialog
            v-if="canShowConfirmationDialog"
            :open-dialog="canShowConfirmationDialog"
            :dialog-message="dialogMessage"
            :dialog-title="dialogTitle"
            :item-id="dialogId"
            :api-config="dialogApiConfig"
            :form-data="dialogRowItem"
            @close-confirmation-dialog="backFromConfirmationDialog"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import DataTableRowDetails from './DataTableRowDetails.vue'
import { formatSqlDate } from '@/global/services/helpers/dates'
import ImagesPreviewDialog from '../../dialogs/ImagesPreviewDialog.vue'
import PdfsPreviewDialog from '../../dialogs/PdfPreviewDialog.vue'
import FormDataDialog from '../../dialogs/FormDataDialog.vue'
import ConfirmationDialog from '../../dialogs/ConfirmationDialog.vue'
import DataTableFilters from './DataTableFilters.vue'
import { api } from '@/global/services/api'
import Button from '@/global/components/buttons/Button.vue'

export default {
  name: 'DataTableLayout',

  components: {
    Button,
    DataTableFilters,
    ConfirmationDialog,
    FormDataDialog,
    PdfsPreviewDialog,
    ImagesPreviewDialog,
    DataTableRowDetails
  },

  props: {
    column: {
      type: Object,
      required: true
    },
    tabItem: {
      type: Object,
      required: true
    },
    tabIndex: {
      type: String,
      required: true
    },
    itemObject: {
      type: Object,
      default: () => {}
    },
    currentTabId: {
      type: Number,
      default: null
    },
    fetch: {
      type: Function,
      default: null
    },
    saveDetailsClicked: {
      type: Boolean,
      default: false
    },
    cancelDetailsClicked: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'set-tab-validation-error-icon',
    'remove-tab-validation-error-icon',
    'filtered-table-items',
    'show-details-action-buttons',
    'hide-details-action-buttons',
    'reset-buttons-click'
  ],

  data () {
    return {
      shouldShowDetails: false,
      currentDetailId: null,
      editObject: {},
      isCreatingDetail: false,
      isTableLoading: false,
      canShowImagesPreviewDialog: false,
      canShowPdfsPreviewDialog: false,
      canShowFormDataDialog: false,
      canShowConfirmationDialog: false,
      dialogApiConfig: null,
      dialogMessage: '',
      dialogTitle: '',
      dialogId: null,
      dialogFields: [],
      dialogRowItem: {},
      imagesToShow: {},
      pdfsToShow: {},
      tableItems: [],
      tableLoadingTimeoutId: null,
      options: {
        page: 1,
        itemsPerPage: null,
        sortBy: [],
        sortDesc: []
      },
      pagination: {
        count: 0,
        currentPage: 1,
        perPage: null,
        total: 0,
        totalPages: 1
      },
      canEdit: true,
      appliedFilters: {},
      imagesConfig: {},
      pdfConfig: {},
      imagesPreviewDialogTitle: '',
      pdfsPreviewDialogTitle: ''
    }
  },

  watch: {
    currentTabId: {
      immediate: true,
      handler () {
        this.backFromDetails()
        this.$emit('remove-tab-validation-error-icon', this.tabIndex)
      }
    },
    shouldShowDetails: {
      handler (shouldShow) {
        if (shouldShow) {
          this.$emit('show-details-action-buttons')
        }
        else {
          this.$emit('hide-details-action-buttons')
        }
      }
    }
  },

  created () {
    this.$nextTick(() => {
      if (this.column && this.column.pagination_numbers && this.column.pagination_numbers.length) {
        this.pagination.perPage = this.column.pagination_numbers[0]
        this.options.itemsPerPage = this.column.pagination_numbers[0]
        this.canEdit = this.checkIsEditActionAvailable()
      }
    })
  },

  methods: {
    formatSqlDate,
    isEmpty,

    getCellContent (key, item) {
      let html = ''

      if (key && item && this.column.display_data_config[key]) {
        if (this.column.display_data_config[key].use_main_object) {
          html = this.column.display_data_config[key].html(item, this.itemObject)
        }
        else {
          html = this.column.display_data_config[key].html(item)
        }
      }

      return html
    },

    async backFromDetails (shouldRefreshTable = false) {
      this.shouldShowDetails = false
      this.isCreatingDetail = false
      this.currentDetailId = null
      this.editObject = {}
      if (shouldRefreshTable) {
        await this.fetchTableData()
      }
      this.$emit('hide-details-action-buttons')
    },

    createNewDetail () {
      this.shouldShowDetails = true
      this.isCreatingDetail = true
    },

    tableRowClick (item) {
      if (this.canEdit) {
        this.shouldShowDetails = true
        this.currentDetailId = item.id
        this.editObject = cloneDeep(item)
      }
    },

    async tableActionClick (action, item) {
      if (!isEmpty(action) && action.key && item && item.id) {
        if (action.key === 'edit') {
          this.shouldShowDetails = true
          this.currentDetailId = item.id
          this.editObject = cloneDeep(item)
        }
        else if (action.key === 'delete') {
          this.canShowConfirmationDialog = true
          this.dialogId = item.id
          this.dialogApiConfig = action.apiConfig ? action.apiConfig : null
          this.dialogMessage = action.dialogMessage ? action.dialogMessage : ''
          this.dialogTitle = action.dialogTitle ? action.dialogTitle : ''
        }
        else if (action.key === 'deactivate') {
          this.canShowConfirmationDialog = true
          this.dialogId = item.id
          this.dialogApiConfig = action.apiConfig ? action.apiConfig : null
          this.dialogMessage = action.dialogMessage ? action.dialogMessage : ''
          this.dialogTitle = action.dialogTitle ? action.dialogTitle : ''
          if (action.parameter) {
            this.dialogRowItem[action.parameter] = false
          }
        }
        else if (action.key === 'activate') {
          this.canShowConfirmationDialog = true
          this.dialogId = item.id
          this.dialogApiConfig = action.apiConfig ? action.apiConfig : null
          this.dialogMessage = action.dialogMessage ? action.dialogMessage : ''
          this.dialogTitle = action.dialogTitle ? action.dialogTitle : ''
          if (action.parameter) {
            this.dialogRowItem[action.parameter] = true
          }
        }
        else if (action.key === 'archive') {
          this.canShowFormDataDialog = true
          this.dialogId = item.id
          this.dialogApiConfig = action.apiConfig ? action.apiConfig : null
          this.dialogMessage = action.dialogMessage ? action.dialogMessage : ''
          this.dialogTitle = action.dialogTitle ? action.dialogTitle : ''
          this.dialogFields = action.dialogFields ? action.dialogFields : []
          this.dialogRowItem = cloneDeep(item)
        }
        else if (action.key === 'send_email') {
          this.canShowFormDataDialog = true
          this.dialogId = item.id
          this.dialogApiConfig = action.apiConfig ? action.apiConfig : null
          this.dialogMessage = action.dialogMessage ? action.dialogMessage : ''
          this.dialogTitle = action.dialogTitle ? action.dialogTitle : ''
          this.dialogFields = action.dialogFields ? action.dialogFields : []
          this.dialogRowItem = cloneDeep(item)
        }
      }
    },

    async backFromFormDataDialog (shouldRefreshTable = false) {
      this.clearVariablesAfterDialogExit()
      this.canShowFormDataDialog = false
      if (shouldRefreshTable) {
        await this.fetchTableData()
      }
    },

    async backFromConfirmationDialog (shouldRefreshTable = false) {
      this.clearVariablesAfterDialogExit()
      this.canShowConfirmationDialog = false
      if (shouldRefreshTable) {
        await this.fetchTableData()
      }
    },

    clearVariablesAfterDialogExit () {
      this.dialogId = null
      this.dialogApiConfig = null
      this.dialogMessage = ''
      this.dialogTitle = ''
      this.dialogFields = []
      this.dialogRowItem = {}
    },

    showImagesPreviewDialog (images, key, item) {
      this.imagesToShow = images
      this.imagesConfig = this.column && this.column.display_data_config && key && this.column.display_data_config[key] ? this.column.display_data_config[key] : {}
      this.canShowImagesPreviewDialog = true
      this.imagesPreviewDialogTitle = this.column && this.column.display_data_config && this.column.display_data_config[key] && this.column.display_data_config[key].preview_dialog_title ? this.column.display_data_config[key].preview_dialog_title(item) : ''
    },

    showPdfFiles (pdfs, key, item) {
      this.pdfsToShow = pdfs
      this.pdfConfig = this.column && this.column.display_data_config && key && this.column.display_data_config[key] ? this.column.display_data_config[key] : {}
      this.canShowPdfsPreviewDialog = true
      this.pdfsPreviewDialogTitle = this.column && this.column.display_data_config && this.column.display_data_config[key] && this.column.display_data_config[key].preview_dialog_title ? this.column.display_data_config[key].preview_dialog_title(item) : ''
    },

    async filterTableItems (filteredTableItems) {
      this.appliedFilters = cloneDeep(filteredTableItems)
      await this.fetchTableData()
    },

    getCellClass (key) {
      const centeredFields = ['image', 'boolean']
      return !!((this.column.display_data_config[key] && this.column.display_data_config[key].type && centeredFields.includes(this.column.display_data_config[key].type)) || key === 'actions')
    },

    async fetchTableData () {
      try {
        this.isTableLoading = true
        const params = {
          pagination: {
            perPage: this.options.itemsPerPage ?? this.pagination.perPage,
            currentPage: this.options.page
          },
          filters: this.appliedFilters
        }

        if (this.column && this.column.indexApiConfig) {
          if (this.column.indexApiConfig.parent_id_name) {
            params[this.column.indexApiConfig.parent_id_name] = this.$route.params.id
          }

          const { data, pagination } = await api()[this.column.indexApiConfig.module].get(this.column.indexApiConfig.route, params)

          if (data) {
            this.tableItems = data
          }

          if (pagination) {
            this.pagination = pagination
          }
        }
      }
      catch (exception) {
        console.log('Exception occurred in DataTableLayout component when tried to get data. Exception: ', exception)
      }
      finally {
        this.isTableLoading = false
      }
    },

    checkIsEditActionAvailable () {
      if (isEmpty(this.column) || !this.column.actions || !this.column.actions.length) {
        return false
      }

      const hasEditAction = this.column.actions.some(action => action && action.key === 'edit')
      return hasEditAction && !!this.column.rowPointer
    }
  }
}
</script>

<style scoped>
::v-deep {
  .row-pointer {
    div.v-data-table__wrapper {
      > table > tbody > tr :hover {
        cursor: pointer;
      }
    }
  }

  div.v-data-table__wrapper {
    > table > tbody > tr > td {
      text-align: center;
    }
  }

  div.v-data-table__wrapper {
    > table > thead > tr > th {
      color: var(--v-primary-base) !important;
    }
  }
  div.v-data-table__wrapper > table > tbody > tr > .td-text-left {
    text-align: left;
  }

  div.v-data-table__wrapper > table > tbody > tr > .td-text-center {
    text-align: center;
  }

  .v-overlay--active {
    background: rgba(241, 235, 235, 0.8) !important;
  }
}

.border-bottom {
  border-bottom: thin solid lightgray;
}
</style>
