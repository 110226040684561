<template>
  <v-menu
    :key="'v-menu-' + field.key + editClicked"
    ref="timePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    width="270px"
    min-height="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        class="text-field-custom"
        ref="input"
        v-model="selectedTime"
        :append-icon="appendIcon"
        :label="getLabel()"
        dense
        :disabled="disabled"
        clearable
        :hide-details="getValidationErrors().length ? false : hideDetails"
        :error-messages="getValidationErrors()"
        v-bind="attrs"
        v-on="on"
        @click:clear="selectedTime = null"
        @input="emitClearValidationError"
      />
    </template>
    <v-time-picker
      v-model="selectedTime"
      full-width
      format="24hr"
      :disabled="disabled"
      @change="emitClearValidationError"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="$refs.timePicker.save(selectedTime)"
      >
        {{ $t('base.ok') }}
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'CustomTimePicker',

  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    field: {
      type: Object,
      default: () => {}
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: 'mdi-clock-outline'
    },
    shouldSetTabValidationErrors: {
      type: Boolean,
      default: false
    },
    collectionObjectIndex: {
      type: String,
      default: null
    },
    collectionName: {
      type: String,
      default: null
    }
  },

  computed: {
    selectedTime: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  watch: {
    validationErrors: {
      immediate: true,
      deep: true,
      handler (errors) {
        if (this.shouldSetTabValidationErrors) {
          if (errors && !isEmpty(errors)) {
            if (this.field && this.field.key) {
              for (const errorKey in errors) {
                if (errorKey === this.field.key) {
                  this.$emit('set-tab-validation-error-icon', this.tabIndex)
                }
                else if (this.collectionObjectIndex && this.collectionName) {
                  const formattedCollectionKey = this.collectionName + '.' + this.collectionObjectIndex + '.' + this.field.key
                  if (errorKey === formattedCollectionKey) {
                    this.$emit('set-tab-validation-error-icon', this.tabIndex)
                  }
                }
              }
            }
          }
          else {
            this.$emit('remove-tab-validation-error-icon', this.tabIndex)
          }
        }
      }
    }
  },

  methods: {
    isEmpty,

    emitClearValidationError () {
      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors', this.field.key)
      }
      else if (this.collectionObjectIndex && this.collectionName) {
        const formattedCollectionKey = this.collectionName + '.' + this.collectionObjectIndex + '.' + this.field.key
        if (this.validationErrors[formattedCollectionKey]) {
          this.$emit('clear-validation-errors', formattedCollectionKey)
        }
      }
    },

    getLabel () {
      if (!this.field) {
        return ''
      }

      // Use label for text field only when specified
      if (this.useLabel) {
        return this.field.label
      }

      return this.field.text_label || ''
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else if (this.collectionObjectIndex && this.collectionName) {
          const formattedCollectionKey = this.collectionName + '.' + this.collectionObjectIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }
      return messages
    },

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    }
  }
}
</script>

<style scoped>
.v-menu__content {
  width: 270px !important;
}

::v-deep.v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    color: #675d5d !important;
  }
::v-deep.text-field-custom > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    font-size: 0.8125rem !important;
  }
</style>
