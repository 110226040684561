<template>
  <div>
    <v-text-field
      ref="input"
      :class="disabled ? 'custom-text-field' : ''"
      v-model="textValue"
      :type="field && field.field_type ? field.field_type : 'text'"
      :dense="dense"
      :flat="flat"
      :hide-details="!hasValidationErrors"
      :clearable="clearable && !readonly"
      :disabled="disabled"
      :error-messages="getValidationErrors()"
      :error-count="getValidationErrors().length || computedRules.length"
      :suffix="suffix"
      :label="getLabel()"
      :rules="computedRules"
      @input="emitClearValidationError"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'CustomTextField',

  props: {
    field: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [Number, String, Object],
      default: null
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    isDynamicField: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    suffix: {
      type: String,
      default: null
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: String,
      default: null
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'clear-validation-errors',
    'edit-clicked',
    'set-tab-validation-error-icon',
    'remove-tab-validation-error-icon',
    'input'
  ],

  data () {
    return {
      customValidationErrorKey: null
    }
  },

  computed: {
    textValue: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },

    computedRules () {
      let rules = []

      if (this.rules.length) {
        rules = [...this.rules]
      }

      if (rules.includes('email')) {
        const emailPattern = value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Upišite ispravnu email adresu'
        }
        rules = rules.map(rule => (rule === 'email' ? emailPattern : rule))
      }

      if (rules.includes('required')) {
        const requiredPattern = value => !!value || 'Ovo polje je obavezno'
        rules = rules.map(rule => (rule === 'required' ? requiredPattern : rule))
      }

      return rules
    },

    hasValidationErrors () {
      const errors = this.getValidationErrors()
      const rules = this.computedRules

      const hasErrors = errors.length > 0
      const hasUnsatisfiedRules = rules.some(rule => {
        return typeof rule === 'function' && rule(this.textValue) !== true
      })

      return hasErrors || hasUnsatisfiedRules
    }
  },

  watch: {
    validationErrors: {
      immediate: true,
      deep: true,
      handler (errors) {
        if (errors && !isEmpty(errors)) {
          if (this.field && this.field.key) {
            for (const errorKey in errors) {
              if (errorKey === this.field.key) {
                this.$emit('set-tab-validation-error-icon', this.tabIndex)
              }
            }
          }
        }
        else {
          this.$emit('remove-tab-validation-error-icon', this.tabIndex)
        }
      }
    }
  },

  methods: {
    isEmpty,

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    emitClearValidationError () {
      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors')
      }
      else {
        this.$emit('remove-tab-validation-error-icon', this.tabIndex)
      }
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
      }

      return messages
    },

    getLabel () {
      if (!this.field) {
        return ''
      }

      // Use label for text field only when specified
      if (this.useLabel) {
        return this.field.label
      }

      return this.field.text_label || ''
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep.v-input {
  font-size: 0.8125rem !important;
}
::v-deep.v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}
::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    color: #777474 !important;
  }
  .v-input > .v-input__control > .v-input__slot > .v-text-field__slot > input, label {
    font-size: 0.8125rem !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .custom-text-field input[type='number'] {
    -moz-appearance:textfield;
  }
  .custom-text-field input::-webkit-outer-spin-button,
  .custom-text-field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>
