<template>
  <v-dialog
    v-model="showImagesPreviewDialog"
    :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    @click:outside="closeDialog"
  >
    <v-card
      style="max-height: 90vh; display: flex; flex-direction: column;"
    >
      <v-card-title
        style="background-color: #eeeeee; font-size: 0.8725rem;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-2"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        style="overflow-y: auto; flex-grow: 1;"
      >
        <v-row>
          <v-col>
            <v-carousel
              :hide-delimiters="!existingImages || !existingImages.length"
              :show-arrows="!(!existingImages || !existingImages.length || existingImages.length === 1)"
              height="100%"
              class="v-carousel"
              @change="setImageForDownload($event)"
            >
              <v-carousel-item
                v-if="!existingImages || existingImages.length === 0 || isEmpty(existingImages)"
                src="/img/default_images/image-not-found.png"
              />
              <v-carousel-item
                v-for="(image, i) in existingImages"
                v-else
                :key="i"
                :src="field && field.read_src_from ? image[field.read_src_from] : image.original_url"
              />
            </v-carousel>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions
        style="background-color: #eeeeee;"
      >
        <v-spacer />
        <v-btn
          class="primary"
          style="font-size: 0.6125rem;"
          @click="closeDialog"
        >
          <v-icon>
            mdi-file-image-remove
          </v-icon>
          {{ $t('base.close') }}
        </v-btn>

        <v-btn
          v-if="imageForDownload"
          class="error"
          style="font-size: 0.6125rem;"
          @click="removeImage"
        >
          <v-icon>
            mdi-delete
          </v-icon>
          {{ $t('base.delete') }}
        </v-btn>

        <v-btn
          v-if="imageForDownload"
          class="success"
          style="font-size: 0.6125rem;"
          :href="imageForDownload"
          download
        >
          <v-icon>
            mdi-download
          </v-icon>
          {{ $t('base/patrol-vehicle.tips_form_recorded_audio_download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { api } from '@/global/services/api'
import { isEmpty } from 'lodash'

export default {
  name: 'ImagesPreviewDialog',

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    images: {
      type: [Object, Array],
      default: null
    },
    field: {
      type: Object,
      default: null
    },
    dialogTitle: {
      type: String,
      default: ''
    }
  },

  emits: [
    'close-images-preview-dialog',
    'updated-images',
    'refresh-table'
  ],

  data () {
    return {
      imageForDownload: null,
      currentImageIndex: null
    }
  },

  computed: {
    showImagesPreviewDialog: {
      get () {
        return this.showDialog
      },
      set (val) {
        return val
      }
    },

    existingImages: {
      get () {
        return this.images
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    existingImages: {
      immediate: true,
      deep: true,
      handler (images) {
        if (!images || !images.length) {
          this.imageForDownload = null
          this.currentImageIndex = null
        }
      }
    }
  },

  methods: {
    isEmpty,
    setImageForDownload (imageIndex) {
      if (this.existingImages && this.existingImages[imageIndex] && this.existingImages[imageIndex].original_url) {
        this.imageForDownload = this.existingImages[imageIndex].original_url
        this.currentImageIndex = imageIndex
      }
    },

    closeDialog () {
      this.showImagesPreviewDialog = false
      this.$emit('close-images-preview-dialog')
    },

    async removeImage () {
      try {
        // Remove image directly using API
        if (this.field && this.field.actions && this.field.actions.delete) {
          await api()[this.field.actions.delete.module][this.field.actions.delete.method](this.field.actions.delete.route + Object.values(this.existingImages)[this.currentImageIndex][this.field.actions.delete.parameter])
          this.$delete(this.existingImages, this.currentImageIndex)
          this.$emit('refresh-table')
        }
        // Otherwise, update main object with removed image
        else {
          this.$delete(this.existingImages, this.currentImageIndex)
          this.$emit('updated-images', this.existingImages)
          this.$emit('refresh-table')
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to remove image in ImagesPreviewDialog. Exception: ', exception)
      }
    }
  }
}
</script>

<style scoped>
::v-deep {
  .v-carousel > .v-carousel__controls {
    background: #a6a3a3 !important;
  }

  .v-window__next > .v-btn {
    height: 35px;
    width: 35px;
  }

  .v-window__next > .v-btn > .v-btn__content > .v-icon {
    font-size: 4rem !important;
  }

  .v-window__next {
    background: #a6a3a3 !important;
  }

  .v-window__prev> .v-btn > .v-btn__content > .v-icon {
    font-size: 4rem !important;
  }

  .v-window__prev > .v-btn {
    height: 35px;
    width: 35px;
  }

  .v-window__prev {
    background: #a6a3a3 !important;
  }
}
</style>
