<template>
  <v-menu
    ref="datePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-height="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="formattedSelectedDate"
        ref="input"
        append-icon="mdi-calendar-month-outline"
        :label="getLabel()"
        dense
        :disabled="disabled"
        clearable
        :hide-details="!isEmpty(validationErrors) && field && field.key && validationErrors[field.key] ? false : hideDetails"
        :error-messages="!isEmpty(validationErrors) && field && field.key && validationErrors[field.key] ? validationErrors[field.key] : []"
        v-bind="attrs"
        v-on="on"
        @click:clear="selectedDate = null"
        @input="emitClearValidationError"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      first-day-of-week="1"
      full-width
      :disabled="disabled"
      @change="emitClearValidationError"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="$refs.datePicker.save(selectedDate)"
      >
        {{ $t('base.ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { formatSqlDate } from '@/global/services/helpers/dates'
import { isEmpty } from 'lodash'

export default {
  name: 'CustomDatePicker',

  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    field: {
      type: Object,
      default: () => {}
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editClicked: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      formattedSelectedDate: null
    }
  },

  computed: {
    selectedDate: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  watch: {
    selectedDate: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.formattedSelectedDate = formatSqlDate(newVal)
        }
        else {
          this.formattedSelectedDate = null
        }
      }
    }
  },

  methods: {
    isEmpty,

    emitClearValidationError () {
      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors')
      }
    },

    getLabel () {
      if (!this.field) {
        return ''
      }

      // Use label for text field only if specified
      if (this.useLabel) {
        return this.field.label
      }

      return this.field.text_label || ''
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    }
  }
}
</script>
