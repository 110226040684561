<template>
  <div>
    <div
      v-for="(field, fieldIndex) in column.items"
      :key="fieldIndex"
    >
      <v-row
        v-if="field.visible"
        class="mb-2"
        style="line-height: unset !important;"
      >
        <!--Label column-->
        <v-col
          cols="4"
        >
          <span
            style="color: #777474; font-size: 0.8125rem"
          >
            {{ field.label }}
          </span>
        </v-col>

        <!--Field column-->
        <v-col
          cols="8"
          style="padding: 0;"
          :class="field.type === 'image' ? 'd-flex align-center justify-start' : ''"
        >
          <!--Text or number field-->
          <div
            v-if="field.type === 'text'"
            style="width: 100%; position: relative;"
            @dblclick="edit(field)"
          >
            <custom-text-field
              v-model="item[field.key]"
              :edit-clicked="isEditClicked"
              :field="field"
              :validation-errors="validationErrors"
              :disabled="!field.editable || !isEditClicked"
              :tab-index="tabIndex"
              @edit-clicked="edit(field)"
              @clear-validation-errors="$emit('clear-validation-errors', field.key)"
              @set-tab-validation-error-icon="$emit('set-tab-validation-error-icon', tabIndex)"
              @remove-tab-validation-error-icon="$emit('remove-tab-validation-error-icon', tabIndex)"
            />
          </div>

          <!--Autocomplete field-->
          <div
            v-if="field.type === 'autocomplete'"
            style="width: 100%; position: relative;"
            @dblclick="edit(field)"
          >
            <custom-autocomplete
              v-model="item[field.key]"
              :options="field.autocomplete_options"
              :disabled="!field.editable || !isEditClicked"
              :dense="true"
              :items="initialAutocompletes  ? initialAutocompletes : []"
              :initial-tab="initialTab"
              :field="field"
              :edit-clicked="isEditClicked"
              :hide-details="true"
              :validation-errors="validationErrors"
              :tab-index="tabIndex"
              should-set-tab-validation-errors
              :append-icon="!isEditClicked ? '' : '$dropdown'"
              label=""
              @edit-clicked="edit(field)"
              @clear-validation-errors="$emit('clear-validation-errors', field.key)"
              @set-tab-validation-error-icon="validationTabIndex => $emit('set-tab-validation-error-icon', validationTabIndex)"
              @remove-tab-validation-error-icon="validationTabIndex => $emit('remove-tab-validation-error-icon', validationTabIndex)"
            />
          </div>

          <!--Combobox field-->
          <div
            v-if="field.type === 'combobox'"
            style="width: 100%; position:relative;"
            @dblclick="edit(field)"
          >
            <custom-combobox
              v-model="item[field.key]"
              :options="field.autocomplete_options"
              :selected="itemObject[field.key]"
              :use-random-colors="true"
              :items="initialAutocompletes ? initialAutocompletes : []"
              :field="field"
              :initial-tab="initialTab"
              :tab-index="tabIndex"
              :dense="true"
              :flat="true"
              :edit-clicked="isEditClicked"
              :hide-details="true"
              :append-icon="!isEditClicked ? '' : '$dropdown'"
              :disabled="!field.editable || !isEditClicked"
              @edit-clicked="edit(field)"
            />
          </div>

          <!--Switch field-->
          <div
            v-if="field.type === 'switch'"
            style="position:relative;"
            @dblclick="edit(field)"
          >
            <custom-switch
              v-model="item[field.key]"
              :field="field"
              :hide-details="true"
              :disabled="!field.editable || !isEditClicked"
            />
          </div>

          <!--Icon image field-->
          <div
            v-if="field.type === 'image'"
          >
            <v-img
              :src="item[field.key] && field.base_path ? field.base_path + item[field.key] : field.base_image_src"
              alt="No status icon"
            />
          </div>

          <!--Image stack preview field-->
          <div
            v-if="field.type === 'image_preview'"
            class="image-preview-container mt-3"
          >
            <div class="image-stack">
              <div
                v-for="(image, index) in item[field.key]"
                :key="index"
                style="position: relative;"
              >
                <v-img
                  :src="isObject(image) ? image.data : image"
                  :class="{ 'main-image': index === 0, 'sub-image': index > 0 }"
                  @click="onShowImagesPreviewDialog(field)"
                />
                <v-btn
                  v-if="field.editable && isEditClicked"
                  small
                  icon
                  class="error"
                  :class="index === 0 ? 'x-button-big-image' : 'x-button-small-image'"
                  @click="removeImage(field, index)"
                >
                  <v-icon
                    style="color: white;"
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
              <div
                @dblclick="edit(field)"
              >
                <v-btn
                  v-if="field.max_images && item[field.key] &&  item[field.key].length < field.max_images"
                  class="ml-3 primary preview-button"
                  :disabled="!field.editable || !isEditClicked"
                  @click="addImage"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

            </div>
            <input
              ref="imageInput"
              type="file"
              style="display: none"
              multiple
              :accept="'image/*'"
              enctype="multipart/form-data"
              @change="handleFileUpload($event, field)"
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <MainImagesPreviewDialog
      v-if="showPreviewImagesDialog"
      :show-dialog="showPreviewImagesDialog"
      :fieldKey="previewImagesDialogFieldKey"
      :images="previewImages"
      :editing="editClicked"
      :dialog-title="previewImagesDialogTitle"
      @close="onCloseImagesPreviewDialog"
      @updated-images="updatedImages => this.item[previewImagesDialogFieldKey] = updatedImages"
    />
  </div>
</template>
<script>
import CustomSwitch from '../../custom-fields/CustomSwitch.vue'
import CustomTextField from '../../custom-fields/CustomTextField.vue'
import CustomCombobox from '../../custom-fields/CustomCombobox.vue'
import CustomAutocomplete from '../../custom-fields/CustomAutocomplete.vue'
import { cloneDeep, forEach, isObject } from 'lodash'
import store from '@/global/store'
import MainImagesPreviewDialog from '../../dialogs/MainImagesPreviewDialog.vue'

export default {
  name: 'LabelValueLayout',

  components: {
    MainImagesPreviewDialog,
    CustomAutocomplete,
    CustomCombobox,
    CustomTextField,
    CustomSwitch
  },

  props: {
    column: {
      type: Object,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    itemObject: {
      type: Object,
      default: () => {}
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    initialAutocompletes: {
      type: [Array, Object],
      default: () => []
    },
    initialTab: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showPreviewImagesDialog: false,
      previewImagesDialogFieldKey: null,
      previewImagesDialogTitle: '-',
      previewImages: []
    }
  },

  computed: {
    isEditClicked: {
      get () {
        return this.editClicked
      },
      set () {
        this.$emit('edit-clicked')
      }
    },
    item: {
      get () {
        return this.itemObject
      },
      set (val) {
        this.$emit('item-object-updated', val)
      }
    }
  },

  methods: {
    isObject,

    edit (field) {
      if (!field) {
        this.isEditClicked = true
      }
      else if (!this.isEditClicked && field.editable) {
        this.isEditClicked = true
      }
    },

    onShowImagesPreviewDialog (field) {
      this.showPreviewImagesDialog = true
      this.previewImagesDialogFieldKey = field.key
      this.previewImages = cloneDeep(this.item[field.key])
      this.previewImagesDialogTitle = field.dialog_title ?? '-'
    },

    onCloseImagesPreviewDialog () {
      this.showPreviewImagesDialog = false
    },

    removeImage (field, index) {
      this.$delete(this.item[field.key], index)
    },

    addImage () {
      this.$refs.imageInput[0].click()
      this.$refs.imageInput[0].value = ''
    },

    handleFileUpload (event, field) {
      const uploadedImages = event.target.files
      let invalidMessages = ''
      const remainingSlots = field.max_images - this.itemObject[field.key].length

      // Checking whether the number of uploaded files exceeds the limit
      if (uploadedImages.length > field.max_images || remainingSlots < uploadedImages.length) {
        store.dispatch('base/notifications/push', this.$t('fleet/vehicle.maximum_images_uploaded').replace(':max-images', field.max_images))
        return
      }

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedImages, uploadedImage => {
        if (!uploadedImage.type.startsWith('image/')) {
          invalidMessages += this.$t('fleet/vehicle.not_valid_image_format_message').replace(':image', uploadedImage.name)
        }
      })

      if (!invalidMessages) {
        forEach(uploadedImages, uploadedImage => {
          const reader = new FileReader()
          reader.readAsDataURL(uploadedImage)
          reader.onload = e => {
            this.item[field.key].push({
              data: e.target.result,
              name: uploadedImage.name
            })
          }
        })
      }
      else {
        store.dispatch('base/notifications/push', invalidMessages)
      }
    }
  }
}
</script>

<style scoped>
.image-preview-container {
  display: flex;
  flex-direction: column;
}

.image-stack {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.main-image {
  width: 60px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
}

.sub-image {
  width: 50px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}
.x-button-big-image {
  position: absolute;
  top: -0.8rem;
  right: 0.1rem;
  color: white;
  height: 20px;
  width: 20px;
}

.x-button-small-image {
  position: absolute;
  top: -0.8rem;
  right: -0.1rem;
  color: white;
  height: 20px;
  width: 20px;
}

@media (max-width: 600px) {
  .image-stack {
    flex-direction: column;
    align-items: flex-start;
  }

  .image-preview-container {
    margin-bottom: 10px;
  }

  .sub-image {
    margin-top: 18px;
  }

  .x-button-big-image {
    top: -1rem;
  }

  .x-button-small-image {
    top: 0.1rem;
  }

  .preview-button {
    margin-left: 0 !important;
    margin-top: 18px;
  }
}
</style>
